/*
  * ALINE.css, Light CSS-Grid Framework;
  * Author: Vladimir "srw" Ozhiganov;
  * Docs: ozhiganov.com/aline/docs
  * Contacts: m@ozhiganov.com
  * Version: 1.0.6 (beta);
*/
html {
	position: relative;
	min-height: 100%;
	font-family: sans-serif
}
* {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box
}
:after, :before {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box
}
body {
	margin: 0
}
button {
	cursor: pointer;
	outline: none;
	border: none;
	background: none;
	padding: 0
}
button, a {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
button:hover, button:active, button:link, button:focus, a:hover, a:active, a:link, a:focus {
	text-decoration: none;
	outline: none
}
label {
	margin-bottom: 0
}
img {
	max-width: 100%
}
input:focus, textarea:focus {
	outline: 0
}д
input, textarea, button {
	-webkit-appearance: none
}
img {
	object-fit: contain;
}
input[type="radio" i] {-webkit-appearance: radio;}
input[type="checkbox" i] {-webkit-appearance: checkbox;}
input[type="button" i] {-webkit-appearance: button;}
[href^=mailto] {user-select: text;-ms-user-select: text;-webkit-user-select: text;moz-user-select: text;}
table {
	border-spacing: 0;
	border-collapse: collapse
}
strong, b {
	font-weight: bold
}
h1, .h1 {
	font-size: 36px
}
h2, .h2 {
	font-size: 30px
}
h3, .h3 {
	font-size: 24px
}
h4, .h4 {
	font-size: 18px
}
h5, .h5 {
	font-size: 14px
}
h6, .h6 {
	font-size: 12px
}
.container, .c {
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px
}
.container-p, .c-p {
	margin-left: auto;
	margin-right: auto
}
.container, .c, .container-p, .c-p {
	width: 1170px
}
.cols-wrap, .c-wrap, .c-w {
	font-size: 0!important;
	padding: 0!important
}
.cols-wrap span, .c-wrap span, .c-w span {
	font-size: 14px
}
.cols-row, .c-row, .c-r, .r, .rf {
	display: -webkit-flex;
	display: flex;
	align-items: flex-start;
	flex-flow: row wrap
}
.stredges, .se, .r, .rf {
	margin-left: -15px;
	margin-right: -15px;
}
.cols-wrap > [class^=col-], .c-wrap > [class^=col-], .c-w > [class^=col-] {
	vertical-align: top
}
/*FOR aline.old-versions*/
.col-wrap {
	font-size: 0!important;
	padding: 0!important;
	display: block!important;
	width: auto!important
}
.col-wrap > [class^=col-] {
	vertical-align: top;
}
/*FOR aline.old-versions*/
[class*="col-"] {
	display: inline-block;
	padding: 0 15px
}
.colp-0, .cp-0 {
	margin: 0!important
}
.colp-5, .cp-5 {
	margin: 0 -5px!important
}
.colp-10, .cp-10 {
	margin: 0 -10px!important
}
.colp-15, .cp-15 {
  	margin: 0 -15px!important
}
.colp-20, .cp-20 {
	margin: 0 -20px!important
}
.colp-0 [class*="col-"], .cp-0 [class*="col-"] {
	padding: 0;
}
.colp-5 [class*="col-"], .cp-5 [class*="col-"] {
	padding: 0 5px;
}
.colp-10 [class*="col-"], .cp-10 [class*="col-"] {
	padding: 0 10px;
}
.colp-15 [class*="col-"], .cp-15 [class*="col-"] {
	padding: 0 15px;
}
.colp-20 [class*="col-"], .cp-20 [class*="col-"] {
	padding: 0 20px;
}
.rf [class*="col-"] {
	display: -webkit-inline-flex;
	display: inline-flex;
}
.p-clear, .p-c {
	font-size: 0!important
}
.pe-auto, .pe-a {
	pointer-events: auto
}
.pe-none, .pe-n {
	pointer-events: none
}
.df {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex
}
.dif {
	display: -webkit-inline-flex;
	display: inline-flex;
}
.fd-row, .fd-r {
	-webkit-flex-direction: row;
	flex-direction: row
}
.fd-rowrs, .fd-rr {
	-webkit-flex-direction: row-reverse;
	flex-direction: row-reverse
}
.fd-col, .fd-c {
	-webkit-flex-direction: column;
	flex-direction: column
}
.fd-colrs, .fd-cr {
	-webkit-flex-direction: column-reverse;
	flex-direction: column-reverse
}
.fw-wrap, .fw-w {
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap
}
.fw-nowrap, .fw-nw {
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap
}
.fw-wraprs, .fw-wr {
	-webkit-flex-wrap: wrap-reverse;
	flex-wrap: wrap-reverse
}
.ff-normal, .ff-n {
	-webkit-flex-flow: row wrap;
	flex-flow: row wrap
}
.jc-start, .jc-s {
	-webkit-justify-content: flex-start;
	justify-content: flex-start
}
.jc-center, .jc-c {
	-webkit-justify-content: center;
	justify-content: center
}
.jc-sbetween, .jc-sb {
	-webkit-justify-content: space-between;
	justify-content: space-between
}
.jc-saround, .jc-sa {
	-webkit-justify-content: space-around;
	justify-content: space-around
}
.jc-end, .jc-e {
	-webkit-justify-content: flex-end;
	justify-content: flex-end
}
.jc-sevenly, .jc-se {
	-webkit-justify-content: space-evenly;
	justify-content: space-evenly
}
.ai-start, .ai-s {
	-webkit-align-items: flex-start;
	align-items: flex-start
}
.ai-center, .ai-c {
	-webkit-align-items: center;
	align-items: center
}
.ai-end, .ai-e {
	-webkit-align-items: flex-end;
	align-items: flex-end
}
.ai-base, .ai-bs {
	-webkit-align-items: baseline;
	align-items: baseline
}
.ai-stretch, .ai-str {
	-webkit-align-items: stretch;
	align-items: stretch
}
.ac-start, .ac-s {
	-webkit-align-content: flex-start;
	align-content: flex-start
}
.ac-center, .ac-c {
	-webkit-align-content: center;
	align-content: center
}
.ac-end, .ac-e {
	-webkit-align-content: flex-end;
	align-content: flex-end
}
.ac-stretch, .ac-str {
	-webkit-align-content: stretch;
	align-content: stretch
}
.ac-sbetween, .ac-sb {
	-webkit-align-content: space-between;
	align-content: space-between
}
.ac-saround, .ac-sa {
	-webkit-align-content: space-around;
	align-content: space-around
}
.as-start, .as-s {
	-webkit-align-self: flex-start;
	align-self: flex-start
}
.as-center, .as-c {
	-webkit-align-self: center;
	align-self: center
}
.as-end, .as-e {
	-webkit-align-self: flex-end;
	align-self: flex-end
}
.as-base, .as-bs {
	-webkit-align-self: baseline;
	align-self: baseline
}
.as-stretch, .as-str {
	-webkit-align-self: stretch;
	align-self: stretch
}
.va-top, .va-t {
	vertical-align: top!important
}
.va-middle, .va-m {
	vertical-align: middle!important
}
.va-bottom, .va-b {
	vertical-align: bottom!important
}
.va-baseline, .va-bs {
	vertical-align: baseline!important
}
.lh-0 {
	line-height: 0
}
.lh-1 {
	line-height: 1
}
.ofw-h {
	overflow: hidden;
}
.ofwx-h {
	overflow-x: hidden;
}
.ofwy-h {
	overflow-x: hidden;
}
.ofw-a {
	overflow: auto;
}
.ofwx-a {
	overflow-x: auto;
}
.ofwy-a {
	overflow-x: auto;
}
.ofw-s {
	overflow: scroll;
}
.ofwx-s {
	overflow-x: scroll;
}
.ofwy-s {
	overflow-x: scroll;
}
.col-0 {
	width: 0%;
}
.col-5 {
	width: 5%
}
.col-8 {
	width: 8.3333333%
}
.col-10 {
	width: 10%
}
.col-15 {
	width: 15%
}
.col-16, .col-17 {
	width: 16.6666667%
}
.col-20 {
	width: 20%
}
.col-25 {
	width: 25%
}
.col-30 {
	width: 30%
}
.col-33, .col-34 {
	width: 33.3333333%
}
.col-35 {
	width: 35%
}
.col-40 {
	width: 40%
}
.col-42 {
	width: 41.6666666%
}
.col-45 {
	width: 45%
}
.col-50 {
	width: 50%
}
.col-55 {
	width: 55%
}
.col-58 {
	width: 58.3333333%
}
.col-60 {
	width: 60%
}
.col-65 {
	width: 65%
}
.col-66, .col-67 {
	width: 66.6666666%
}
.col-70 {
	width: 70%
}
.col-75, .col-76 {
	width: 75%
}
.col-80 {
	width: 80%
}
.col-83, .col-84 {
	width: 83.3333333%
}
.col-85 {
	width: 85%
}
.col-90 {
	width: 90%
}
.col-92 {
	width: 91.6666667%
}
.col-95 {
	width: 95%
}
.col-100 {
	width: 100%
}
.offset-0, .offset-none, .of-0, .of-n {
	margin-left: 0
}
.offset-5, .of-5 {
	margin-left: 5%
}
.offset-8, .of-8 {
	margin-left: 8.3333333%
}
.offset-10, .of-10 {
	margin-left: 10%
}
.offset-15, .of-15 {
	margin-left: 15%
}
.offset-16, .offset-17, .of-16, .of-17 {
	margin-left: 16.6666667%
}
.offset-20, .of-20 {
	margin-left: 20%
}
.offset-25, .of-25 {
	margin-left: 25%
}
.offset-30, .of-30 {
	margin-left: 30%
}
.offset-33, .offset-34, .of-33, .of-34 {
	margin-left: 33.3333333%
}
.offset-35, .of-35 {
	margin-left: 35%
}
.offset-40, .of-40 {
	margin-left: 40%
}
.offset-42, .of-42 {
	margin-left: 41.6666666%
}
.offset-45, .of-45 {
	margin-left: 45%
}
.offset-50, .of-50 {
	margin-left: 50%
}
.offset-55, .of-55 {
	margin-left: 55%
}
.offset-58, .of-58 {
	margin-left: 58.3333333%
}
.offset-60, .of-60 {
	margin-left: 60%
}
.offset-65, .of-65 {
	margin-left: 65%
}
.offset-66, .offset-67, .of-66, .of-67 {
	margin-left: 66.6666666%
}
.offset-70, .of-70 {
	margin-left: 70%
}
.offset-75, .of-75 {
	margin-left: 75%
}
.offset-80, .of-80 {
	margin-left: 80%
}
.offset-83, .offset-84, .of-83, .of-84 {
	margin-left: 83.3333333%
}
.offset-85, .of-85 {
	margin-left: 85%
}
.offset-90, .of-90 {
	margin-left: 90%
}
.offset-92, .of-92 {
	margin-left: 91.6666667%
}
.offset-95, .of-95 {
	margin-left: 95%
}
.offset-100, .of-100 {
	margin-left: 100%
}
[class*=push-], [class*=pull-] {
	position: relative
}
.order--1, .or--1 {
	order: -1
}
.order-0, .or-0 {
	order: 0
}
.order-1, .or-1 {
	order: 1
}
.push-0, .push-none, .push-n {
	left: 0!important
}
.push-5 {
	left: 5%
}
.push-8 {
	left: 8.3333333%
}
.push-10 {
	left: 10%
}
.push-15 {
	left: 15%
}
.push-16, .push-17 {
	left: 16.6666667%
}
.push-20 {
	left: 20%
}
.push-25 {
	left: 25%
}
.push-30 {
	left: 30%
}
.push-33, .push-34 {
	left: 33.3333333%
}
.push-35 {
	left: 35%
}
.push-40 {
	left: 40%
}
.push-42 {
	left: 41.6666666%
}
.push-45 {
	left: 45%
}
.push-50 {
	left: 50%
}
.push-55 {
	left: 55%
}
.push-58 {
	left: 58.3333333%
}
.push-60 {
	left: 60%
}
.push-65 {
	left: 65%
}
.push-66, .push-67 {
	left: 66.6666666%
}
.push-70 {
	left: 70%
}
.push-75 {
	left: 75%
}
.push-80 {
	left: 80%
}
.push-83, .push-84 {
	left: 83.3333333%
}
.push-85 {
	left: 85%
}
.push-90 {
	left: 90%
}
.push-92 {
	left: 91.6666667%
}
.push-95 {
	left: 95%
}
.push-100 {
	left: 100%
}
.pull-0, .pull-none, .pull-n {
	right: 0!important
}
.pull-5 {
	right: 5%
}
.pull-8 {
	right: 8.3333333%
}
.pull-10 {
	right: 10%
}
.pull-15 {
	right: 15%
}
.pull-16, .pull-17 {
	right: 16.6666667%
}
.pull-20 {
	right: 20%
}
.pull-25 {
	right: 25%
}
.pull-30 {
	right: 30%
}
.pull-33, .pull-34 {
	right: 33.3333333%
}
.pull-35 {
	right: 35%
}
.pull-40 {
	right: 40%
}
.pull-42 {
	right: 41.6666666%
}
.pull-45 {
	right: 45%
}
.pull-50 {
	right: 50%
}
.pull-55 {
	right: 55%
}
.pull-58 {
	right: 58.3333333%
}
.pull-60 {
	right: 60%
}
.pull-65 {
	right: 65%
}
.pull-66, .pull-67 {
	right: 66.6666666%
}
.pull-70 {
	right: 70%
}
.pull-75 {
	right: 75%
}
.pull-80 {
	right: 80%
}
.pull-83, .pull-84 {
	right: 83.3333333%
}
.pull-85 {
	right: 85%
}
.pull-90 {
	right: 90%
}
.pull-92 {
	right: 91.6666667%
}
.pull-95 {
	right: 95%
}
.pull-100 {
	right: 100%
}
.hidden, [hidden], template {
	display: none!important;
	visibility: hidden
}
.no-padt, .no-pt {
	padding-top: 0!important
}
.no-padb, .no-pb {
	padding-bottom: 0!important
}
.no-padl, .no-pl {
	padding-left: 0!important
}
.no-padr, .no-pr {
	padding-right: 0!important
}
.no-border, .no-b {
	border: none!important
}
.no-margin, .no-m {
	margin: 0!important
}
.no-pad, .no-p {
	padding: 0!important
}
.t-center, .t-c {
	text-align: center!important
}
.t-left, .t-l {
	text-align: left!important
}
.t-right, .t-r {
	text-align: right!important
}
.t-justify, .t-j {
	text-align: justify!important;
	text-align-last: justify!important;
	-ms-text-align-last: justify!important
}
.ha-center, .ha-c {
	margin: 0 auto;
	display: block
}
.pos-r, .p-r {
	position: relative
}
.pos-s, .p-s {
	position: static
}
.dib {
	display: inline-block
}
.db {
	display: block
}
.w-dib {
	display: inline-block;
	width: 100%
}
.w-100 {
	width: 100%!important
}
.h-100 {
	height: 100%!important
}
.h-inherit, .h-i {
	height: inherit
}
.w-inherit, .w-i {
	width: inherit
}
.clearfix {
	clear: both;
	float: none
}
.no-select, .no-s {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.t-nowrap, .t-nw {
	white-space: nowrap
}
.tt-lower, .tt-lc {
	text-transform: lowercase
}
.tt-upper, .tt-uc {
	text-transform: uppercase
}
.tt-capit, .tt-c {
	text-transform: capitalize
}
.tt-none, .tt-n {
	text-transform: none
}
.t-mute {
	color: #888
}
.visible {
	display: block;
	visibility: visible
}
.f-left, .f-l {
	float: left
}
.f-right, .f-r {
	float: right
}
.f-none, .f-n {
	float: none
}
.tran-none, .tr-n {
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none
}
.tran-50, .tr-50 {
	-webkit-transition: all .05s;
	-moz-transition: all .05s;
	-ms-transition: all .05s;
	-o-transition: all .05s;
	transition: all .05s
}
.tran-100, .tr-100 {
	-webkit-transition: all .1s;
	-moz-transition: all .1s;
	-ms-transition: all .1s;
	-o-transition: all .1s;
	transition: all .1s
}
.tran-150, .tr-150 {
	-webkit-transition: all .15s;
	-moz-transition: all .15s;
	-ms-transition: all .15s;
	-o-transition: all .15s;
	transition: all .15s
}
.tran-200, .tr-200 {
	-webkit-transition: all .2s;
	-moz-transition: all .2s;
	-ms-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s
}
.tran-250, .tr-250 {
	-webkit-transition: all .25s;
	-moz-transition: all .25s;
	-ms-transition: all .25s;
	-o-transition: all .25s;
	transition: all .25s
}
.tran-300, .tr-300 {
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-ms-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s
}
.tran-e-50, .tr-e-50 {
	-webkit-transition: all .05s ease;
	-moz-transition: all .05s ease;
	-ms-transition: all .05s ease;
	-o-transition: all .05s ease;
	transition: all .05s ease
}
.tran-e-100, .tr-e-100 {
	-webkit-transition: all .1s ease;
	-moz-transition: all .1s ease;
	-ms-transition: all .1s ease;
	-o-transition: all .1s ease;
	transition: all .1s ease
}
.tran-e-150, .tr-e-150 {
	-webkit-transition: all .15s ease;
	-moz-transition: all .15s ease;
	-ms-transition: all .15s ease;
	-o-transition: all .15s ease;
	transition: all .15s ease
}
.tran-e-200, .tr-e-200 {
	-webkit-transition: all .2s ease;
	-moz-transition: all .2s ease;
	-ms-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease
}
.tran-e-250, .tr-e-250 {
	-webkit-transition: all .25s ease;
	-moz-transition: all .25s ease;
	-ms-transition: all .25s ease;
	-o-transition: all .25s ease;
	transition: all .25s ease
}
.tran-e-300, .tr-e-300 {
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease
}
.tran-eout-50, .tr-eout-50 {
	-webkit-transition: all .05s ease-in-out;
	-moz-transition: all .05s ease-in-out;
	-ms-transition: all .05s ease-in-out;
	-o-transition: all .05s ease-in-out;
	transition: all .05s ease-in-out
}
.tran-eout-100, .tr-eout-100 {
	-webkit-transition: all .1s ease-in-out;
	-moz-transition: all .1s ease-in-out;
	-ms-transition: all .1s ease-in-out;
	-o-transition: all .1s ease-in-out;
	transition: all .1s ease-in-out
}
.tran-eout-150, .tr-eout-150 {
	-webkit-transition: all .15s ease-in-out;
	-moz-transition: all .15s ease-in-out;
	-ms-transition: all .15s ease-in-out;
	-o-transition: all .15s ease-in-out;
	transition: all .15s ease-in-out
}
.tran-eout-200, .tr-eout-200 {
	-webkit-transition: all .2s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	-ms-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out
}
.tran-eout-250, .tr-eout-250 {
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
	-ms-transition: all .25s ease-in-out;
	-o-transition: all .25s ease-in-out;
	transition: all .25s ease-in-out
}
.tran-eout-300, .tr-eout-300 {
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-ms-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out
}
.m-0 {
	margin: 0!important
}
/*EXPERIMENTAL VARS*/
.m-1 {
	margin: var(--margin-1, 5px)!important
}
.m-2 {
	margin: var(--margin-2, 15px)!important
}
.m-3 {
	margin: var(--margin-3, 20px)!important
}
.m-4 {
	margin: var(--margin-4, 30px)!important
}
.m-5 {
	margin: var(--margin-5, 50px)!important
}
.mt-1 {
	margin-top: var(--margin-1, 5px)!important
}
.mt-2 {
	margin-top: var(--margin-2, 15px)!important
}
.mt-3 {
	margin-top: var(--margin-3, 20px)!important
}
.mt-4 {
	margin-top: var(--margin-4, 30px)!important
}
.mt-5 {
	margin-top: var(--margin-5, 50px)!important
}
.mr-1 {
	margin-right: var(--margin-1, 5px)!important
}
.mr-2 {
	margin-right: var(--margin-2, 15px)!important
}
.mr-3 {
	margin-right: var(--margin-3, 20px)!important
}
.mr-4 {
	margin-right: var(--margin-4, 30px)!important
}
.mr-5 {
	margin-right: var(--margin-5, 50px)!important
}
.mb-1 {
	margin-bottom: var(--margin-1, 5px)!important
}
.mb-2 {
	margin-bottom: var(--margin-2, 15px)!important
}
.mb-3 {
	margin-bottom: var(--margin-3, 20px)!important
}
.mb-4 {
	margin-bottom: var(--margin-4, 30px)!important
}
.mb-5 {
	margin-bottom: var(--margin-5, 50px)!important
}
.ml-1 {
	margin-left: var(--margin-1, 5px)!important
}
.ml-2 {
	margin-left: var(--margin-2, 15px)!important
}
.ml-3 {
	margin-left: var(--margin-3, 20px)!important
}
.ml-4 {
	margin-left: var(--margin-4, 30px)!important
}
.ml-5 {
	margin-left: var(--margin-5, 50px)!important
}
/*EXPERIMENTAL VARS*/
.mt-0 {
	margin-top: 0!important
}
.mt-5 {
	margin-top: 5px!important
}
.mt-10 {
	margin-top: 10px!important
}
.mt-15 {
	margin-top: 15px!important
}
.mt-20 {
	margin-top: 20px!important
}
.mt-25 {
	margin-top: 25px!important
}
.mt-30 {
	margin-top: 30px!important
}
.mt-35 {
	margin-top: 35px!important
}
.mt-40 {
	margin-top: 40px!important
}
.mt-45 {
	margin-top: 45px!important
}
.mt-50 {
	margin-top: 50px!important
}
.mr-0 {
	margin-right: 0!important
}
.mr-5 {
	margin-right: 5px!important
}
.mr-10 {
	margin-right: 10px!important
}
.mr-15 {
	margin-right: 15px!important
}
.mr-20 {
	margin-right: 20px!important
}
.mr-25 {
	margin-right: 25px!important
}
.mr-30 {
	margin-right: 30px!important
}
.mr-35 {
	margin-right: 35px!important
}
.mr-40 {
	margin-right: 40px!important
}
.mr-45 {
	margin-right: 45px!important
}
.mr-50 {
	margin-right: 50px!important
}
.mb-0 {
	margin-bottom: 0!important
}
.mb-5 {
	margin-bottom: 5px!important
}
.mb-10 {
	margin-bottom: 10px!important
}
.mb-15 {
	margin-bottom: 15px!important
}
.mb-20 {
	margin-bottom: 20px!important
}
.mb-25 {
	margin-bottom: 25px!important
}
.mb-30 {
	margin-bottom: 30px!important
}
.mb-35 {
	margin-bottom: 35px!important
}
.mb-40 {
	margin-bottom: 40px!important
}
.mb-45 {
	margin-bottom: 45px!important
}
.mb-50 {
	margin-bottom: 50px!important
}
.ml-0 {
	margin-left: 0!important
}
.ml-5 {
	margin-left: 5px!important
}
.ml-10 {
	margin-left: 10px!important
}
.ml-15 {
	margin-left: 15px!important
}
.ml-20 {
	margin-left: 20px!important
}
.ml-25 {
	margin-left: 25px!important
}
.ml-30 {
	margin-left: 30px!important
}
.ml-35 {
	margin-left: 35px!important
}
.ml-40 {
	margin-left: 40px!important
}
.ml-45 {
	margin-left: 45px!important
}
.ml-50 {
	margin-left: 50px!important
}
.mtb-0 {
	margin-top: 0!important;
	margin-bottom: 0!important
}
.mtb-5 {
	margin-top: 5px!important;
	margin-bottom: 5px!important
}
.mtb-10 {
	margin-top: 10px!important;
	margin-bottom: 10px!important;
}
.mtb-15 {
	margin-top: 15px!important;
	margin-bottom: 15px!important;
}
.mtb-20 {
	margin-top: 20px!important;
	margin-bottom: 20px!important
}
.mtb-25 {
	margin-top: 25px!important;
	margin-bottom: 25px!important
}
.mtb-30 {
	margin-top: 30px!important;
	margin-bottom: 30px!important
}
.mtb-35 {
	margin-top: 35px!important;
	margin-bottom: 35px!important
}
.mtb-40 {
	margin-top: 40px!important;
 	margin-bottom: 40px!important
}
.mtb-45 {
	margin-top: 45px!important;
	margin-bottom: 45px!important
}
.mtb-50 {
	margin-top: 50px!important;
	margin-bottom: 50px!important
}
.mtb-60 {
	margin-top: 60px!important;
	margin-bottom: 60px!important
}
.mtb-70 {
	margin-top: 70px!important;
	margin-bottom: 70px!important
}
.mtb-80 {
	margin-top: 80px!important;
	margin-bottom: 80px!important
}
.mtb-90 {
	margin-top: 90px!important;
	margin-bottom: 90px!important
}
.mtb-100 {
	margin-top: 100px!important;
	margin-bottom: 100px!important
}
.mlr-0 {
	margin-left: 0px!important;
	margin-right: 0px!important
}
.mlr-5 {
	margin-left: 5px!important;
	margin-right: 5px!important
}
.mlr-10 {
	margin-left: 10px!important;
	margin-right: 10px!important
}
.mlr-15 {
	margin-left: 15px!important;
	margin-right: 15px!important
}
.mlr-20 {
	margin-left: 20px!important;
	margin-right: 20px!important
}
.mlr-25 {
	margin-left: 25px!important;
	margin-right: 25px!important
}
.mlr-30 {
	margin-left: 30px!important;
	margin-right: 30px!important
}
.mlr-35 {
	margin-left: 35px!important;
	margin-right: 35px!important
}
.mlr-40 {
	margin-left: 40px!important;
	margin-right: 40px!important
}
.mlr-45 {
	margin-left: 45px!important;
	margin-right: 45px!important
}
.mlr-50 {
	margin-left: 50px!important;
	margin-right: 50px!important
}
.mlr-60 {
	margin-left: 60px!important;
	margin-right: 60px!important
}
.mlr-70 {
	margin-left: 70px!important;
	margin-right: 70px!important
}
.mlr-80 {
	margin-left: 80px!important;
	margin-right: 80px!important
}
.mlr-90 {
	margin-left: 90px!important;
	margin-right: 90px!important
}
.mlr-100 {
	margin-left: 100px!important;
	margin-right: 100px!important
}
.pt-0 {
	padding-top: 0!important
}
.pt-5 {
	padding-top: 5px!important
}
.pt-10 {
	padding-top: 10px!important
}
.pt-15 {
	padding-top: 15px!important
}
.pt-20 {
	padding-top: 20px!important
}
.pt-25 {
	padding-top: 25px!important
}
.pt-30 {
	padding-top: 30px!important
}
.pt-35 {
	padding-top: 35px!important
}
.pt-40 {
	padding-top: 40px!important
}
.pt-45 {
	padding-top: 45px!important
}
.pt-50 {
	padding-top: 50px!important
}
.pr-0 {
	padding-right: 0!important
}
.pr-5 {
	padding-right: 5px!important
}
.pr-10 {
	padding-right: 10px!important
}
.pr-15 {
	padding-right: 15px!important
}
.pr-20 {
	padding-right: 20px!important
}
.pr-25 {
	padding-right: 25px!important
}
.pr-30 {
	padding-right: 30px!important
}
.pr-35 {
	padding-right: 35px!important
}
.pr-40 {
	padding-right: 40px!important
}
.pr-45 {
	padding-right: 45px!important
}
.pr-50 {
	padding-right: 50px!important
}
.pb-0 {
	padding-bottom: 0!important
}
.pb-5 {
	padding-bottom: 5px!important
}
.pb-10 {
	padding-bottom: 10px!important
}
.pb-15 {
	padding-bottom: 15px!important
}
.pb-20 {
	padding-bottom: 20px!important
}
.pb-25 {
	padding-bottom: 25px!important
}
.pb-30 {
	padding-bottom: 30px!important
}
.pb-35 {
	padding-bottom: 35px!important
}
.pb-40 {
	padding-bottom: 40px!important
}
.pb-45 {
	padding-bottom: 45px!important
}
.pb-50 {
	padding-bottom: 50px!important
}
.pl-0 {
	padding-left: 0!important
}
.pl-5 {
	padding-left: 5px!important
}
.pl-10 {
	padding-left: 10px!important
}
.pl-15 {
	padding-left: 15px!important
}
.pl-20 {
	padding-left: 20px!important
}
.pl-25 {
	padding-left: 25px!important
}
.pl-30 {
	padding-left: 30px!important
}
.pl-35 {
	padding-left: 35px!important
}
.pl-40 {
	padding-left: 40px!important
}
.pl-45 {
	padding-left: 45px!important
}
.pl-50 {
	padding-left: 50px!important
}
.p-0 {
	padding: 0!important
}
.p-5 {
	padding: 5px!important
}
.p-10 {
	padding: 10px!important
}
.p-15 {
	padding: 15px!important
}
.p-20 {
	padding: 20px!important
}
.p-25 {
	padding: 25px!important
}
.p-30 {
	padding: 30px!important
}
.p-35 {
	padding: 35px!important
}
.p-40 {
	padding: 40px!important
}
.p-45 {
	padding: 45px!important
}
.p-50 {
	padding: 50px!important
}
.ptb-0 {
	padding-top: 0px!important;
	padding-bottom: 0px!important
}
.ptb-5 {
	padding-top: 5px!important;
	padding-bottom: 5px!important
}
.ptb-10 {
	padding-top: 10px!important;
	padding-bottom: 10px!important
}
.ptb-15 {
	padding-top: 15px!important;
	padding-bottom: 15px!important
}
.ptb-20 {
	padding-top: 20px!important;
	padding-bottom: 20px!important
}
.ptb-25 {
	padding-top: 25px!important;
	padding-bottom: 25px!important
}
.ptb-30 {
	padding-top: 30px!important;
	padding-bottom: 30px!important
}
.ptb-35 {
	padding-top: 35px!important;
	padding-bottom: 35px!important
}
.ptb-40 {
	padding-top: 40px!important;
	padding-bottom: 40px!important
}
.ptb-45 {
	padding-top: 45px!important;
	padding-bottom: 45px!important
}
.ptb-50 {
	padding-top: 50px!important;
	padding-bottom: 50px!important
}
.ptb-60 {
	padding-top: 60px!important;
	padding-bottom: 60px!important
}
.ptb-70 {
	padding-top: 70px!important;
	padding-bottom: 70px!important
}
.ptb-80 {
	padding-top: 80px!important;
	padding-bottom: 80px!important
}
.ptb-90 {
	padding-top: 90px!important;
	padding-bottom: 90px!important
}
.ptb-100 {
	padding-top: 100px!important;
	padding-bottom: 100px!important
}
.plr-0 {
	padding-left: 0px!important;
	padding-right: 0px!important
}
.plr-5 {
	padding-left: 5px!important;
	padding-right: 5px!important
}
.plr-10 {
	padding-left: 10px!important;
	padding-right: 10px!important
}
.plr-15 {
	padding-left: 15px!important;
	padding-right: 15px!important
}
.plr-20 {
	padding-left: 20px!important;
	padding-right: 20px!important
}
.plr-25 {
	padding-left: 25x!important;
	padding-right: 25px!important
}
.plr-30 {
	padding-left: 30px!important;
	padding-right: 30px!important
}
.plr-35 {
	padding-left: 35px!important;
	padding-right: 35px!important
}
.plr-40 {
	padding-left: 40px!important;
	padding-right: 40px!important
}
.plr-45 {
	padding-left: 45px!important;
	padding-right: 45px!important
}
.plr-50 {
	padding-left: 50px!important;
	padding-right: 50px!important
}
.plr-60 {
	padding-left: 60px!important;
	padding-right: 60px!important
}
.plr-70 {
	padding-left: 70px!important;
	padding-right: 70px!important
}
.plr-80 {
	padding-left: 80px!important;
	padding-right: 80px!important
}
.plr-90 {
	padding-left: 90px!important;
	padding-right: 90px!important
}
.plr-100 {
	padding-left: 100px!important;
	padding-right: 100px!important
}
::-webkit-input-placeholder {
	color: #666;
	opacity: 1
}
:-moz-placeholder {
	color: #666;
	opacity: 1
}
::-moz-placeholder {
	color: #666;
	opacity: 1
}
:-ms-input-placeholder {
	color: #666;
	opacity: 1
}
body input:focus:required:invalid,
body input:required:valid,
body textarea:focus:required:invalid,
body textarea:required:valid {
	color: #666
}
.data {
	display: inline-block;
	width: 100%;
	font-size: 16px
}
.data :first-child {
	margin-top:	0
}
.data p {
	font-size: 1em;
	line-height: 1.6
}
.data ul, .data ol {
	font-size: 1em;
	line-height: 1.6;
}
.data span {
	font-size: 1em;
}
.data table {
	font-size: 1em;
	width: 100%
}
.zi--1 {
	z-index: -1;
}
.zi-0 {
 	z-index: 0;
}
.zi-1 {
	z-index: 1;
}
.zi-2 {
	z-index: 2;
}
.zi-3 {
	z-index: 3;
}
.zi-inf {
	z-index: 9999999999999;
}
@media only screen and (min-width: 1580px) {
	/* ### If needed to use XL-resolution - uncomment 3 strokes after this ###*/
	.container, .container-p, .c, .c-p {
		width: 1520px
	}
	.col-xl-0 {
		width: 0%
	}
	.col-xl-5 {
		width: 5%
	}
	.col-xl-8 {
		width: 8.3333333%
	}
	.col-xl-10 {
		width: 10%
	}
	.col-xl-15 {
		width: 15%
	}
	.col-xl-16, .col-xl-17 {
		width: 16.6666667%
	}
	.col-xl-20 {
		width: 20%
	}
	.col-xl-25 {
		width: 25%
	}
	.col-xl-30 {
		width: 30%
	}
	.col-xl-33, .col-xl-34 {
		width: 33.3333333%
	}
	.col-xl-35 {
		width: 35%
	}
	.col-xl-40 {
		width: 40%
	}
	.col-xl-42 {
		width: 41.6666666%
	}
	.col-xl-45 {
		width: 45%
	}
	.col-xl-50 {
		width: 50%
	}
	.col-xl-55 {
		width: 55%
	}
	.col-xl-58 {
		width: 58.3333333%
	}
	.col-xl-60 {
		width: 60%
	}
	.col-xl-65 {
		width: 65%
	}
	.col-xl-66, .col-xl-67 {
		width: 66.6666666%
	}
	.col-xl-70 {
		width: 70%
	}
	.col-xl-75, .col-xl-76 {
		width: 75%
	}
	.col-xl-80 {
		width: 80%
	}
	.col-xl-83, .col-xl-84 {
		width: 83.3333333%
	}
	.col-xl-85 {
		width: 85%
	}
	.col-xl-90 {
		width: 90%
	}
	.col-xl-92 {
		width: 91.6666667%
	}
	.col-xl-95 {
		width: 95%
	}
	.col-xl-100 {
		width: 100%
	}
	.stredges-xl, .se-xl {
		margin-left: -15px!important;
		margin-right: -15px!important
	}
	.stredges-xl-0, .se-xl-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.pe-xl-auto, .pe-xl-a {
		pointer-events: auto
	}
	.pe-xl-none, .pe-xl-n {
		pointer-events: none
	}
	.offset-xl-0, .offset-xl-none, .of-xl-n, .of-xl-0 {
		margin-left: 0!important
	}
	.push-xl-0, .push-xl-none, .push-xl-n {
		left: 0!important
	}
	.pull-xl-0, .pull-xl-none, .pull-xl-n {
		right: 0!important
	}
	.t-xl-center, .t-xl-c {
		text-align: center!important
	}
	.t-xl-left, .t-xl-l {
		text-align: left!important
	}
	.t-xl-right, .t-xl-r {
		text-align: right!important
	}
	.t-xl-justify, .t-xl-j {
		text-align: justify!important;
		-ms-text-align-last: justify!important;
		text-align-last: justify!important
	}
	.va-xl-top, .va-xl-t {
		vertical-align: middle
	}
	.va-xl-bottom, .va-xl-b {
		vertical-align: middle
	}
	.va-xl-middle, .va-xl-m {
		vertical-align: middle
	}
	.fd-xl-row, .fd-xl-r {
		-webkit-flex-direction: row;
		flex-direction: row
	}
	.fd-xl-rowrs, .fd-xl-rr {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.fd-xl-col, .fd-xl-c {
		-webkit-flex-direction: column;
		flex-direction: column
	}
	.fd-xl-colrs, .fd-xl-cr {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.fw-xl-wrap, .fw-xl-w {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.fw-xl-nowrap, .fw-xl-nw {
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.fw-xl-wraprs, .fw-xl-wr {
		-webkit-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse
	}
	.ff-xl-normal, .ff-xl-n {
		-webkit-flex-flow: row wrap;
		flex-flow: row wrap
	}
	.jc-xl-start, .jc-xl-s {
		-webkit-justify-content: flex-start;
		justify-content: flex-start
	}
	.jc-xl-center, .jc-xl-c {
		-webkit-justify-content: center;
		justify-content: center
	}
	.jc-xl-end, .jc-xl-e {
		-webkit-justify-content: flex-end;
		justify-content: flex-end
	}
	.jc-xl-sbetween, .jc-xl-sb {
		-webkit-justify-content: space-between;
		justify-content: space-between
	}
	.jc-xl-saround, .jc-xl-sa {
		-webkit-justify-content: space-around;
		justify-content: space-around
	}
	.jc-xl-sevenly, .jc-xl-se {
		-webkit-justify-content: space-evenly;
		justify-content: space-evenly
	}
	.ac-xl-start, .ac-xl-s {
		-webkit-align-content: flex-start;
		align-content: flex-start
	}
	.ac-xl-center, .ac-xl-c {
		-webkit-align-content: center;
		align-content: center
	}
	.ac-xl-end, .ac-xl-e {
		-webkit-align-content: flex-end;
		align-content: flex-end
	}
	.ac-xl-stretch, .ac-xl-str {
		-webkit-align-content: stretch;
		align-content: stretch
	}
	.ac-xl-sbetween, .ac-xl-sb {
		-webkit-align-content: space-between;
		align-content: space-between
	}
	.ac-xl-saround, .ac-xl-sa {
		-webkit-align-content: space-around;
		align-content: space-around
	}
	.ai-xl-start, .ai-xl-s {
		-webkit-align-items: flex-start;
		align-items: flex-start
	}
	.ai-xl-center, .ai-xl-c {
		-webkit-align-items: center;
		align-items: center
	}
	.ai-xl-end, .ai-xl-e {
		-webkit-align-items: flex-end;
		align-items: flex-end
	}
	.ai-xl-base, .ai-xl-bs {
		-webkit-align-items: baseline;
		align-items: baseline
	}
	.ai-xl-stretch, .ai-xl-str {
		-webkit-align-items: stretch;
		align-items: stretch
	}
	.as-xl-start, .as-xl-s {
		-webkit-align-self: flex-start;
		align-self: flex-start
	}
	.as-xl-center, .as-xl-c {
		-webkit-align-self: center;
		align-self: center
	}
	.as-xl-end, .as-xl-e {
		-webkit-align-self: flex-end;
		align-self: flex-end
	}
	.as-xl-base, .as-xl-bs {
		-webkit-align-self: baseline;
		align-self: baseline
	}
	.as-xl-stretch, .as-xl-str {
		-webkit-align-self: stretch;
		align-self: stretch
	}
	.order-xl--1, .or-xl--1 {
		order: -1
	}
	.order-xl-0, .or-xl-0 {
		order: 0
	}
	.order-xl-1, .or-xl-1 {
		order: 1
	}
	.lh-xl-0 {
		line-height: 0
	}
	.lh-xl-1 {
		line-height: 1
	}
	.ofw-xl-h {
		overflow: hidden;
	}
	.ofwx-xl-h {
		overflow-x: hidden;
	}
	.ofwy-xl-h {
		overflow-x: hidden;
	}
	.ofw-xl-a {
		overflow: auto;
	}
	.ofwx-xl-a {
		overflow-x: auto;
	}
	.ofwy-xl-a {
		overflow-x: auto;
	}
	.ofw-xl-s {
		overflow: scroll;
	}
	.ofwx-xl-s {
		overflow-x: scroll;
	}
	.ofwy-xl-s {
		overflow-x: scroll;
	}
	.h-xl-inherit, .h-xl-i {
		height: inherit
	}
	.w-xl-inherit, .w-xl-i {
		width: inherit
	}
	/*EXPERIMENTAL VARS*/
	.m-xl-1 {
		margin: var(--margin-1, 5px)!important
	}
	.m-xl-2 {
		margin: var(--margin-2, 15px)!important
	}
	.m-xl-3 {
		margin: var(--margin-3, 20px)!important
	}
	.m-xl-4 {
		margin: var(--margin-4, 30px)!important
	}
	.m-xl-5 {
		margin: var(--margin-5, 50px)!important
	}
	.mt-xl-1 {
		margin-top: var(--margin-1, 5px)!important
	}
	.mt-xl-2 {
		margin-top: var(--margin-2, 15px)!important
	}
	.mt-xl-3 {
		margin-top: var(--margin-3, 20px)!important
	}
	.mt-xl-4 {
		margin-top: var(--margin-4, 30px)!important
	}
	.mt-xl-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mr-xl-1 {
		margin-right: var(--margin-1, 5px)!important
	}
	.mr-xl-2 {
		margin-right: var(--margin-2, 15px)!important
	}
	.mr-xl-3 {
		margin-right: var(--margin-3, 20px)!important
	}
	.mr-xl-4 {
		margin-right: var(--margin-4, 30px)!important
	}
	.mr-xl-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mb-xl-1 {
		margin-bottom: var(--margin-1, 5px)!important
	}
	.mb-xl-2 {
		margin-bottom: var(--margin-2, 15px)!important
	}
	.mb-xl-3 {
		margin-bottom: var(--margin-3, 20px)!important
	}
	.mb-xl-4 {
		margin-bottom: var(--margin-4, 30px)!important
	}
	.mb-xl-5 {
		margin-bottom: var(--margin-5, 50px)!important
	}
	.ml-xl-1 {
		margin-left: var(--margin-1, 5px)!important
	}
	.ml-xl-2 {
		margin-left: var(--margin-2, 15px)!important
	}
	.ml-xl-3 {
		margin-left: var(--margin-3, 20px)!important
	}
	.ml-xl-4 {
		margin-left: var(--margin-4, 30px)!important
	}
	.ml-xl-5 {
		margin-left: var(--margin-5, 50px)!important
	}
	/*EXPERIMENTAL VARS*/
	.m-xl-0 {
		margin: 0!important
	}
	.mt-xl-0 {
		margin-top: 0!important
	}
	.mt-xl-5 {
		margin-top: 5px!important
	}
	.mt-xl-10 {
		margin-top: 10px!important
	}
	.mt-xl-15 {
		margin-top: 15px!important
	}
	.mt-xl-20 {
		margin-top: 20px!important
	}
	.mt-xl-25 {
		margin-top: 25px!important
	}
	.mt-xl-30 {
		margin-top: 30px!important
	}
	.mt-xl-35 {
		margin-top: 35px!important
	}
	.mt-xl-40 {
		margin-top: 40px!important
	}
	.mt-xl-45 {
		margin-top: 45px!important
	}
	.mt-xl-50 {
		margin-top: 50px!important
	}
	.mr-xl-0 {
		margin-right: 0!important
	}
	.mr-xl-5 {
		margin-right: 5px!important
	}
	.mr-xl-10 {
		margin-right: 10px!important
	}
	.mr-xl-15 {
		margin-right: 15px!important
	}
	.mr-xl-20 {
		margin-right: 20px!important
	}
	.mr-xl-25 {
		margin-right: 25px!important
	}
	.mr-xl-30 {
		margin-right: 30px!important
	}
	.mr-xl-35 {
		margin-right: 35px!important
	}
	.mr-xl-40 {
		margin-right: 40px!important
	}
	.mr-xl-45 {
		margin-right: 45px!important
	}
	.mr-xl-50 {
		margin-right: 50px!important
	}
	.mb-xl-0 {
		margin-bottom: 0!important
	}
	.mb-xl-5 {
		margin-bottom: 5px!important
	}
	.mb-xl-10 {
		margin-bottom: 10px!important
	}
	.mb-xl-15 {
		margin-bottom: 15px!important
	}
	.mb-xl-20 {
		margin-bottom: 20px!important
	}
	.mb-xl-25 {
		margin-bottom: 25px!important
	}
	.mb-xl-30 {
		margin-bottom: 30px!important
	}
	.mb-xl-35 {
		margin-bottom: 35px!important
	}
	.mb-xl-40 {
		margin-bottom: 40px!important
	}
	.mb-xl-45 {
		margin-bottom: 45px!important
	}
	.mb-xl-50 {
		margin-bottom: 50px!important
	}
	.ml-xl-0 {
		margin-left: 0!important
	}
	.ml-xl-5 {
		margin-left: 5px!important
	}
	.ml-xl-10 {
		margin-left: 10px!important
	}
	.ml-xl-15 {
		margin-left: 15px!important
	}
	.ml-xl-20 {
		margin-left: 20px!important
	}
	.ml-xl-25 {
		margin-left: 25px!important
	}
	.ml-xl-30 {
		margin-left: 30px!important
	}
	.ml-xl-35 {
		margin-left: 35px!important
	}
	.ml-xl-40 {
		margin-left: 40px!important
	}
	.ml-xl-45 {
		margin-left: 45px!important
	}
	.ml-xl-50 {
		margin-left: 50px!important
	}
	.mtb-xl-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.mtb-xl-5 {
		margin-top: 5px!important;
		margin-bottom: 5px!important
	}
	.mtb-xl-10 {
		margin-top: 10px!important;
		margin-bottom: 10px!important
	}
	.mtb-xl-15 {
		margin-top: 15px!important;
		margin-bottom: 15px!important
	}
	.mtb-xl-20 {
		margin-top: 20px!important;
		margin-bottom: 20px!important
	}
	.mtb-xl-25 {
		margin-top: 25px!important;
		margin-bottom: 25px!important
	}
	.mtb-xl-30 {
		margin-top: 30px!important;
		margin-bottom: 30px!important
	}
	.mtb-xl-35 {
		margin-top: 35px!important;
		margin-bottom: 35px!important
	}
	.mtb-xl-40 {
		margin-top: 40px!important;
		margin-bottom: 40px!important
	}
	.mtb-xl-45 {
		margin-top: 45px!important;
		margin-bottom: 45px!important
	}
	.mtb-xl-50 {
		margin-top: 50px!important;
		margin-bottom: 50px!important
	}
	.mtb-xl-60 {
		margin-top: 60px!important;
		margin-bottom: 60px!important
	}
	.mtb-xl-70 {
		margin-top: 70px!important;
		margin-bottom: 70px!important
	}
	.mtb-xl-80 {
		margin-top: 80px!important;
		margin-bottom: 80px!important
	}
	.mtb-xl-90 {
		margin-top: 90px!important;
		margin-bottom: 90px!important
	}
	.mtb-xl-100 {
		margin-top: 100px!important;
		margin-bottom: 100px!important
	}
	.mlr-xl-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.mlr-xl-5 {
		margin-left: 5px!important;
		margin-right: 5px!important
	}
	.mlr-xl-10 {
		margin-left: 10px!important;
		margin-right: 10px!important
	}
	.mlr-xl-15 {
		margin-left: 15px!important;
		margin-right: 15px!important
	}
	.mlr-xl-20 {
		margin-left: 20px!important;
		margin-right: 20px!important
	}
	.mlr-xl-25 {
		margin-left: 25px!important;
		margin-right: 25px!important
	}
	.mlr-xl-30 {
		margin-left: 30px!important;
		margin-right: 30px!important
	}
	.mlr-xl-35 {
		margin-left: 35px!important;
		margin-right: 35px!important
	}
	.mlr-xl-40 {
		margin-left: 40px!important;
		margin-right: 40px!important
	}
	.mlr-xl-45 {
		margin-left: 45px!important;
		margin-right: 45px!important
	}
	.mlr-xl-50 {
		margin-left: 50px!important;
		margin-right: 50px!important
	}
	.mlr-xl-60 {
		margin-left: 60px!important;
		margin-right: 60px!important
	}
	.mlr-xl-70 {
		margin-left: 70px!important;
		margin-right: 70px!important
	}
	.mlr-xl-80 {
		margin-left: 80px!important;
		margin-right: 80px!important
	}
	.mlr-xl-90 {
		margin-left: 90px!important;
		margin-right: 90px!important
	}
	.mlr-xl-100 {
		margin-left: 100px!important;
		margin-right: 100px!important
	}
	.p-xl-0 {
		padding: 0!important
	}
	.pt-xl-0 {
		padding-top: 0!important
	}
	.pt-xl-5 {
		padding-top: 5px!important
	}
	.pt-xl-10 {
		padding-top: 10px!important
	}
	.pt-xl-15 {
		padding-top: 15px!important
	}
	.pt-xl-20 {
		padding-top: 20px!important
	}
	.pt-xl-25 {
		padding-top: 25px!important
	}
	.pt-xl-30 {
		padding-top: 30px!important
	}
	.pt-xl-35 {
		padding-top: 35px!important
	}
	.pt-xl-40 {
		padding-top: 40px!important
	}
	.pt-xl-45 {
		padding-top: 45px!important
	}
	.pt-xl-50 {
		padding-top: 50px!important
	}
	.pr-xl-0 {
		padding-right: 0!important
	}
	.pr-xl-5 {
		padding-right: 5px!important
	}
	.pr-xl-10 {
		padding-right: 10px!important
	}
	.pr-xl-15 {
		padding-right: 15px!important
	}
	.pr-xl-20 {
		padding-right: 20px!important
	}
	.pr-xl-25 {
		padding-right: 25px!important
	}
	.pr-xl-30 {
		padding-right: 30px!important
	}
	.pr-xl-35 {
		padding-right: 35px!important
	}
	.pr-xl-40 {
		padding-right: 40px!important
	}
	.pr-xl-45 {
		padding-right: 45px!important
	}
	.pr-xl-50 {
		padding-right: 50px!important
	}
	.pb-xl-0 {
		padding-bottom: 0!important
	}
	.pb-xl-5 {
		padding-bottom: 5px!important
	}
	.pb-xl-10 {
		padding-bottom: 10px!important
	}
	.pb-xl-15 {
		padding-bottom: 15px!important
	}
	.pb-xl-20 {
		padding-bottom: 20px!important
	}
	.pb-xl-25 {
		padding-bottom: 25px!important
	}
	.pb-xl-30 {
		padding-bottom: 30px!important
	}
	.pb-xl-35 {
		padding-bottom: 35px!important
	}
	.pb-xl-40 {
		padding-bottom: 40px!important
	}
	.pb-xl-45 {
		padding-bottom: 45px!important
	}
	.pb-xl-50 {
		padding-bottom: 50px!important
	}
	.pl-xl-0 {
		padding-left: 0!important
	}
	.pl-xl-5 {
		padding-left: 5px!important
	}
	.pl-xl-10 {
		padding-left: 10px!important
	}
	.pl-xl-15 {
		padding-left: 15px!important
	}
	.pl-xl-20 {
		padding-left: 20px!important
	}
	.pl-xl-25 {
		padding-left: 25px!important
	}
	.pl-xl-30 {
		padding-left: 30px!important
	}
	.pl-xl-35 {
		padding-left: 35px!important
	}
	.pl-xl-40 {
		padding-left: 40px!important
	}
	.pl-xl-45 {
		padding-left: 45px!important
	}
	.pl-xl-50 {
		padding-left: 50px!important
	}
	.p-xl-0 {
		padding: 0!important
	}
	.p-xl-5 {
		padding: 5px!important
	}
	.p-xl-10 {
		padding: 10px!important
	}
	.p-xl-15 {
		padding: 15px!important
	}
	.p-xl-20 {
		padding: 20px!important
	}
	.p-xl-25 {
		padding: 25px!important
	}
	.p-xl-30 {
		padding: 30px!important
	}
	.p-xl-35 {
		padding: 35px!important
	}
	.p-xl-40 {
		padding: 40px!important
	}
	.p-xl-45 {
		padding: 45px!important
	}
	.p-xl-50 {
		padding: 50px!important
	}
	.ptb-xl-0 {
		padding-top: 0px!important;
		padding-bottom: 0px!important
	}
	.ptb-xl-5 {
		padding-top: 5px!important;
		padding-bottom: 5px!important
	}
	.ptb-xl-10 {
		padding-top: 10px!important;
		padding-bottom: 10px!important
	}
	.ptb-xl-15 {
		padding-top: 15px!important;
		padding-bottom: 15px!important
	}
	.ptb-xl-20 {
		padding-top: 20px!important;
		padding-bottom: 20px!important
	}
	.ptb-xl-25 {
		padding-top: 25px!important;
		padding-bottom: 25px!important
	}
	.ptb-xl-30 {
		padding-top: 30px!important;
		padding-bottom: 30px!important
	}
	.ptb-xl-35 {
		padding-top: 35px!important;
		padding-bottom: 35px!important
	}
	.ptb-xl-40 {
		padding-top: 40px!important;
		padding-bottom: 40px!important
	}
	.ptb-xl-45 {
		padding-top: 45px!important;
		padding-bottom: 45px!important
	}
	.ptb-xl-50 {
		padding-top: 50px!important;
		padding-bottom: 50px!important
	}
	.ptb-xl-60 {
		padding-top: 60px!important;
		padding-bottom: 60px!important
	}
	.ptb-xl-70 {
		padding-top: 70px!important;
		padding-bottom: 70px!important
	}
	.ptb-xl-80 {
		padding-top: 80px!important;
		padding-bottom: 80px!important
	}
	.ptb-xl-90 {
		padding-top: 90px!important;
		padding-bottom: 90px!important
	}
	.ptb-xl-100 {
		padding-top: 100px!important;
		padding-bottom: 100px!important
	}
	.plr-xl-0 {
		padding-left: 0px!important;
		padding-right: 0px!important
	}
	.plr-xl-5 {
		padding-left: 5px!important;
		padding-right: 5px!important
	}
	.plr-xl-10 {
		padding-left: 10px!important;
		padding-right: 10px!important
	}
	.plr-xl-15 {
		padding-left: 15px!important;
		padding-right: 15px!important
	}
	.plr-xl-20 {
		padding-left: 20px!important;
		padding-right: 20px!important
	}
	.plr-xl-25 {
		padding-left: 25px!important;
		padding-right: 25px!important
	}
	.plr-xl-30 {
		padding-left: 30px!important;
		padding-right: 30px!important
	}
	.plr-xl-35 {
		padding-left: 35px!important;
		padding-right: 35px!important
	}
	.plr-xl-40 {
		padding-left: 40px!important;
		padding-right: 40px!important
	}
	.plr-xl-45 {
		padding-left: 45px!important;
		padding-right: 45px!important
	}
	.plr-xl-50 {
		padding-left: 50px!important;
		padding-right: 50px!important
	}
	.plr-xl-60 {
		padding-left: 60px!important;
		padding-right: 60px!important
	}
	.plr-xl-70 {
		padding-left: 70px!important;
		padding-right: 70px!important
	}
	.plr-xl-80 {
		padding-left: 80px!important;
		padding-right: 80px!important
	}
	.plr-xl-90 {
		padding-left: 90px!important;
		padding-right: 90px!important
	}
	.plr-xl-100 {
		padding-left: 100px!important;
		padding-right: 100px!important
	}
	h1, .h1 {
		font-size: 34px
	}
	h2, .h2 {
		font-size: 28px
	}
	h3, .h3 {
		font-size: 22px
	}
	h4, .h4 {
		font-size: 18px
	}
	h5, .h5 {
		font-size: 14px
	}
	h6, .h6 {
		font-size: 12px
	}
	.colp-xl-0, .cp-xl-0 {
		margin: 0!important
	}
	.colp-xl-5, .cp-xl-5 {
		margin: 0 -5px!important
	}
	.colp-xl-10, .cp-xl-10 {
		margin: 0 -10px!important
	}
	.colp-xl-15, .cp-xl-15 {
		margin: 0 -15px!important
	}
	.colp-xl-20, .cp-xl-20 {
		margin: 0 -20px!important
	}
	.colp-xl-0 [class*="col-"], .cp-xl-0 [class*="col-"] {
		padding: 0
	}
	.colp-xl-5 [class*="col-"], .cp-xl-5 [class*="col-"] {
		padding: 0 5px
	}
	.colp-xl-10 [class*="col-"], .cp-xl-10 [class*="col-"] {
		padding: 0 10px
	}
	.colp-xl-15 [class*="col-"], .cp-xl-15 [class*="col-"] {
		padding: 0 15px
	}
	.colp-xl-20 [class*="col-"], .cp-xl-20 [class*="col-"] {
		padding: 0 20px
	}
	.zi-xl--1 {
		z-index: -1;
	}
	.zi-xl-0 {
		z-index: 0;
	}
	.zi-xl-1 {
		z-index: 1;
	}
	.zi-xl-2 {
		z-index: 2;
	}
	.zi-xl-3 {
		z-index: 3;
	}
	.zi-xl-inf {
		z-index: 9999999999999;
	}
}
@media only screen and (max-width: 1579px) {
	.container, .container-p, .c, .c-p {
		width: 1170px
	}
	.col-l-0 {
		width: 0%
	}
	.col-l-5 {
		width: 5%
	}
	.col-l-8 {
		width: 8.3333333%
	}
	.col-l-10 {
		width: 10%
	}
	.col-l-15 {
		width: 15%
	}
	.col-l-16, .col-l-17 {
		width: 16.6666667%
	}
	.col-l-20 {
		width: 20%
	}
	.col-l-25 {
		width: 25%
	}
	.col-l-30 {
		width: 30%
	}
	.col-l-33, .col-l-34 {
		width: 33.3333333%
	}
	.col-l-35 {
		width: 35%
	}
	.col-l-40 {
		width: 40%
	}
	.col-l-42 {
		width: 41.6666666%
	}
	.col-l-45 {
		width: 45%
	}
	.col-l-50 {
		width: 50%
	}
	.col-l-55 {
		width: 55%
	}
	.col-l-58 {
		width: 58.3333333%
	}
	.col-l-60 {
		width: 60%
	}
	.col-l-65 {
		width: 65%
	}
	.col-l-66, .col-l-67 {
		width: 66.6666666%
	}
	.col-l-70 {
		width: 70%
	}
	.col-l-75, .col-l-76 {
		width: 75%
	}
	.col-l-80 {
		width: 80%
	}
	.col-l-83, .col-l-84 {
		width: 83.3333333%
	}
	.col-l-85 {
		width: 85%
	}
	.col-l-90 {
		width: 90%
	}
	.col-l-92 {
		width: 91.6666667%
	}
	.col-l-95 {
		width: 95%
	}
	.col-l-100 {
		width: 100%
	}
	.stredges-l, .se-l {
		margin-left: -15px!important;
		margin-right: -15px!important
	}
	.stredges-l-0, .se-l-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.pe-l-auto, .pe-l-a {
		pointer-events: auto
	}
	.pe-l-none, .pe-l-n {
		pointer-events: none
	}
	.offset-l-0, .offset-l-none, .of-l-n, .of-l-0 {
		margin-left: 0!important
	}
	.push-l-0, .push-l-none, .push-l-n {
		left: 0!important
	}
	.pull-l-0, .pull-l-none, .pull-l-n {
		right: 0!important
	}
	.t-l-center, .t-l-c {
		text-align: center!important
	}
	.t-l-left, .t-l-l {
		text-align: left!important
	}
	.t-l-right, .t-l-r {
		text-align: right!important
	}
	.t-l-justify, .t-l-j {
		text-align: justify!important;
		-ms-text-align-last: justify!important;
		text-align-last: justify!important
	}
	.va-l-top, .va-l-t {
		vertical-align: middle
	}
	.va-l-bottom, .va-l-b {
		vertical-align: middle
	}
	.va-l-middle, .va-l-m {
		vertical-align: middle
	}
	.fd-l-row, .fd-l-r {
		-webkit-flex-direction: row;
		flex-direction: row
	}
	.fd-l-rowrs, .fd-l-rr {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.fd-l-col, .fd-l-c {
		-webkit-flex-direction: column;
		flex-direction: column
	}
	.fd-l-colrs, .fd-l-cr {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.fw-l-wrap, .fw-l-w {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.fw-l-nowrap, .fw-l-nw {
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.fw-l-wraprs, .fw-l-wr {
		-webkit-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse
	}
	.ff-l-normal, .ff-l-n {
		-webkit-flex-flow: row wrap;
		flex-flow: row wrap
	}
	.jc-l-start, .jc-l-s {
		-webkit-justify-content: flex-start;
		justify-content: flex-start
	}
	.jc-l-center, .jc-l-c {
		-webkit-justify-content: center;
		justify-content: center
	}
	.jc-l-end, .jc-l-e {
		-webkit-justify-content: flex-end;
		justify-content: flex-end
	}
	.jc-l-sbetween, .jc-l-sb {
		-webkit-justify-content: space-between;
		justify-content: space-between
	}
	.jc-l-saround, .jc-l-sa {
		-webkit-justify-content: space-around;
		justify-content: space-around
	}
	.jc-l-sevenly, .jc-l-se {
		-webkit-justify-content: space-evenly;
		justify-content: space-evenly
	}
	.ac-l-start, .ac-l-s {
		-webkit-align-content: flex-start;
		align-content: flex-start
	}
	.ac-l-center, .ac-l-c {
		-webkit-align-content: center;
		align-content: center
	}
	.ac-l-end, .ac-l-e {
		-webkit-align-content: flex-end;
		align-content: flex-end
	}
	.ac-l-stretch, .ac-l-str {
		-webkit-align-content: stretch;
		align-content: stretch
	}
	.ac-l-sbetween, .ac-l-sb {
		-webkit-align-content: space-between;
		align-content: space-between
	}
	.ac-l-saround, .ac-l-sa {
		-webkit-align-content: space-around;
		align-content: space-around
	}
	.ai-l-start, .ai-l-s {
		-webkit-align-items: flex-start;
		align-items: flex-start
	}
	.ai-l-center, .ai-l-c {
		-webkit-align-items: center;
		align-items: center
	}
	.ai-l-end, .ai-l-e {
		-webkit-align-items: flex-end;
		align-items: flex-end
	}
	.ai-l-base, .ai-l-bs {
		-webkit-align-items: baseline;
		align-items: baseline
	}
	.ai-l-stretch, .ai-l-str {
		-webkit-align-items: stretch;
		align-items: stretch
	}
	.as-l-start, .as-l-s {
		-webkit-align-self: flex-start;
		align-self: flex-start
	}
	.as-l-center, .as-l-c {
		-webkit-align-self: center;
		align-self: center
	}
	.as-l-end, .as-l-e {
		-webkit-align-self: flex-end;
		align-self: flex-end;
	}
	.as-l-base, .as-l-bs {
		-webkit-align-self: baseline;
		align-self: baseline
	}
	.as-l-stretch, .as-l-str {
		-webkit-align-self: stretch;
		align-self: stretch
	}
	.order-l--1, .or-l--1 {
		order: -1
	}
	.order-l-0, .or-l-0 {
		order: 0
	}
	.order-l-1, .or-l-1 {
		order: 1
	}
	.lh-l-0 {
		line-height: 0
	}
	.lh-l-1 {
		line-height: 1
	}
	.ofw-l-h {
		 overflow: hidden;
	}
	.ofwx-l-h {
		overflow-x: hidden;
	}
	.ofwy-l-h {
		overflow-x: hidden;
	}
	.ofw-l-a {
		overflow: auto;
	}
	.ofwx-l-a {
		overflow-x: auto;
	}
	.ofwy-l-a {
		overflow-x: auto;
	}
	.ofw-l-s {
		overflow: scroll;
	}
	.ofwx-l-s {
		overflow-x: scroll;
	}
	.ofwy-l-s {
		overflow-x: scroll;
	}
	.h-l-inherit, .h-l-i {
		height: inherit
	}
	.w-l-inherit, .w-l-i {
		width: inherit
	}
	/*EXPERIMENTAL VARS*/
	.m-l-1 {
		margin: var(--margin-1, 5px)!important
	}
	.m-l-2 {
		margin: var(--margin-2, 15px)!important
	}
	.m-l-3 {
		margin: var(--margin-3, 20px)!important
	}
	.m-l-4 {
		margin: var(--margin-4, 30px)!important
	}
	.m-l-5 {
		margin: var(--margin-5, 50px)!important
	}
	.mt-l-1 {
		margin-top: var(--margin-1, 5px)!important
	}
	.mt-l-2 {
		margin-top: var(--margin-2, 15px)!important
	}
	.mt-l-3 {
		margin-top: var(--margin-3, 20px)!important
	}
	.mt-l-4 {
		margin-top: var(--margin-4, 30px)!important
	}
	.mt-l-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mr-l-1 {
		margin-right: var(--margin-1, 5px)!important
	}
	.mr-l-2 {
		margin-right: var(--margin-2, 15px)!important
	}
	.mr-l-3 {
		margin-right: var(--margin-3, 20px)!important
	}
	.mr-l-4 {
		margin-right: var(--margin-4, 30px)!important
	}
	.mr-l-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mb-l-1 {
		margin-bottom: var(--margin-1, 5px)!important
	}
	.mb-l-2 {
		margin-bottom: var(--margin-2, 15px)!important
	}
	.mb-l-3 {
		margin-bottom: var(--margin-3, 20px)!important
	}
	.mb-l-4 {
		margin-bottom: var(--margin-4, 30px)!important
	}
	.mb-l-5 {
		margin-bottom: var(--margin-5, 50px)!important
	}
	.ml-l-1 {
		margin-left: var(--margin-1, 5px)!important
	}
	.ml-l-2 {
		margin-left: var(--margin-2, 15px)!important
	}
	.ml-l-3 {
		margin-left: var(--margin-3, 20px)!important
	}
	.ml-l-4 {
		margin-left: var(--margin-4, 30px)!important
	}
	.ml-l-5 {
		margin-left: var(--margin-5, 50px)!important
	}
	/*EXPERIMENTAL VARS*/
	.m-l-0 {
		margin: 0!important
	}
	.mt-l-0 {
		margin-top: 0!important
	}
	.mt-l-5 {
		margin-top: 5px!important
	}
	.mt-l-10 {
		margin-top: 10px!important
	}
	.mt-l-15 {
		margin-top: 15px!important
	}
	.mt-l-20 {
		margin-top: 20px!important
	}
	.mt-l-25 {
		margin-top: 25px!important
	}
	.mt-l-30 {
		margin-top: 30px!important
	}
	.mt-l-35 {
		margin-top: 35px!important
	}
	.mt-l-40 {
		margin-top: 40px!important
	}
	.mt-l-45 {
		margin-top: 45px!important
	}
	.mt-l-50 {
		margin-top: 50px!important
	}
	.mr-l-0 {
		margin-right: 0!important
	}
	.mr-l-5 {
		margin-right: 5px!important
	}
	.mr-l-10 {
		margin-right: 10px!important
	}
	.mr-l-15 {
		margin-right: 15px!important
	}
	.mr-l-20 {
		margin-right: 20px!important
	}
	.mr-l-25 {
		margin-right: 25px!important
	}
	.mr-l-30 {
		margin-right: 30px!important
	}
	.mr-l-35 {
		margin-right: 35px!important
	}
	.mr-l-40 {
		margin-right: 40px!important
	}
	.mr-l-45 {
		margin-right: 45px!important
	}
	.mr-l-50 {
		margin-right: 50px!important
	}
	.mb-l-0 {
		margin-bottom: 0!important
	}
	.mb-l-5 {
		margin-bottom: 5px!important
	}
	.mb-l-10 {
		margin-bottom: 10px!important
	}
	.mb-l-15 {
		margin-bottom: 15px!important
	}
	.mb-l-20 {
		margin-bottom: 20px!important
	}
	.mb-l-25 {
		margin-bottom: 25px!important
	}
	.mb-l-30 {
		margin-bottom: 30px!important
	}
	.mb-l-35 {
		margin-bottom: 35px!important
	}
	.mb-l-40 {
		margin-bottom: 40px!important
	}
	.mb-l-45 {
		margin-bottom: 45px!important
	}
	.mb-l-50 {
		margin-bottom: 50px!important
	}
	.ml-l-0 {
		margin-left: 0!important
	}
	.ml-l-5 {
		margin-left: 5px!important
	}
	.ml-l-10 {
		margin-left: 10px!important
	}
	.ml-l-15 {
		margin-left: 15px!important
	}
	.ml-l-20 {
		margin-left: 20px!important
	}
	.ml-l-25 {
		margin-left: 25px!important
	}
	.ml-l-30 {
		margin-left: 30px!important
	}
	.ml-l-35 {
		margin-left: 35px!important
	}
	.ml-l-40 {
		margin-left: 40px!important
	}
	.ml-l-45 {
		margin-left: 45px!important
	}
	.ml-l-50 {
		margin-left: 50px!important
	}
	.mtb-l-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.mtb-l-5 {
		margin-top: 5px!important;
		margin-bottom: 5px!important
	}
	.mtb-l-10 {
		margin-top: 10px!important;
		margin-bottom: 10px!important
	}
	.mtb-l-15 {
		margin-top: 15px!important;
		margin-bottom: 15px!important
	}
	.mtb-l-20 {
		margin-top: 20px!important;
		margin-bottom: 20px!important
	}
	.mtb-l-25 {
		margin-top: 25px!important;
		margin-bottom: 25px!important
	}
	.mtb-l-30 {
		margin-top: 30px!important;
		margin-bottom: 30px!important
	}
	.mtb-l-35 {
		margin-top: 35px!important;
		margin-bottom: 35px!important
	}
	.mtb-l-40 {
		margin-top: 40px!important;
		margin-bottom: 40px!important
	}
	.mtb-l-45 {
		margin-top: 45px!important;
		margin-bottom: 45px!important
	}
	.mtb-l-50 {
		margin-top: 50px!important;
		margin-bottom: 50px!important
	}
	.mtb-l-60 {
		margin-top: 60px!important;
		margin-bottom: 60px!important
	}
	.mtb-l-70 {
		margin-top: 70px!important;
		margin-bottom: 70px!important
	}
	.mtb-l-80 {
		margin-top: 80px!important;
		margin-bottom: 80px!important
	}
	.mtb-l-90 {
		margin-top: 90px!important;
		margin-bottom: 90px!important
	}
	.mtb-l-100 {
		margin-top: 100px!important;
		margin-bottom: 100px!important
	}
	.mlr-l-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.mlr-l-5 {
		margin-left: 5px!important;
		margin-right: 5px!important
	}
	.mlr-l-10 {
		margin-left: 10px!important;
		margin-right: 10px!important
	}
	.mlr-l-15 {
		margin-left: 15px!important;
		margin-right: 15px!important
	}
	.mlr-l-20 {
		margin-left: 20px!important;
		margin-right: 20px!important
	}
	.mlr-l-25 {
		margin-left: 25px!important;
		margin-right: 25px!important
	}
	.mlr-l-30 {
		margin-left: 30px!important;
		margin-right: 30px!important
	}
	.mlr-l-35 {
		margin-left: 35px!important;
		margin-right: 35px!important
	}
	.mlr-l-40 {
		margin-left: 40px!important;
		margin-right: 40px!important
	}
	.mlr-l-45 {
		margin-left: 45px!important;
		margin-right: 45px!important
	}
	.mlr-l-50 {
		margin-left: 50px!important;
		margin-right: 50px!important
	}
	.mlr-l-60 {
		margin-left: 60px!important;
		margin-right: 60px!important
	}
	.mlr-l-70 {
		margin-left: 70px!important;
		margin-right: 70px!important
	}
	.mlr-l-80 {
		margin-left: 80px!important;
		margin-right: 80px!important
	}
	.mlr-l-90 {
		margin-left: 90px!important;
		margin-right: 90px!important
	}
	.mlr-l-100 {
		margin-left: 100px!important;
		margin-right: 100px!important
	}
	.p-l-0 {
		padding: 0!important
	}
	.pt-l-0 {
		padding-top: 0!important
	}
	.pt-l-5 {
		padding-top: 5px!important
	}
	.pt-l-10 {
		padding-top: 10px!important
	}
	.pt-l-15 {
		padding-top: 15px!important
	}
	.pt-l-20 {
		padding-top: 20px!important
	}
	.pt-l-25 {
		padding-top: 25px!important
	}
	.pt-l-30 {
		padding-top: 30px!important
	}
	.pt-l-35 {
		padding-top: 35px!important
	}
	.pt-l-40 {
		padding-top: 40px!important
	}
	.pt-l-45 {
		padding-top: 45px!important
	}
	.pt-l-50 {
		padding-top: 50px!important
	}
	.pr-l-0 {
		padding-right: 0!important
	}
	.pr-l-5 {
		padding-right: 5px!important
	}
	.pr-l-10 {
		padding-right: 10px!important
	}
	.pr-l-15 {
		padding-right: 15px!important
	}
	.pr-l-20 {
		padding-right: 20px!important
	}
	.pr-l-25 {
		padding-right: 25px!important
	}
	.pr-l-30 {
		padding-right: 30px!important
	}
	.pr-l-35 {
		padding-right: 35px!important
	}
	.pr-l-40 {
		padding-right: 40px!important
	}
	.pr-l-45 {
		padding-right: 45px!important
	}
	.pr-l-50 {
		padding-right: 50px!important
	}
	.pb-l-0 {
		padding-bottom: 0!important
	}
	.pb-l-5 {
		padding-bottom: 5px!important
	}
	.pb-l-10 {
		padding-bottom: 10px!important
	}
	.pb-l-15 {
		padding-bottom: 15px!important
	}
	.pb-l-20 {
		padding-bottom: 20px!important
	}
	.pb-l-25 {
		padding-bottom: 25px!important
	}
	.pb-l-30 {
		padding-bottom: 30px!important
	}
	.pb-l-35 {
		padding-bottom: 35px!important
	}
	.pb-l-40 {
		padding-bottom: 40px!important
	}
	.pb-l-45 {
		padding-bottom: 45px!important
	}
	.pb-l-50 {
		padding-bottom: 50px!important
	}
	.pl-l-0 {
		padding-left: 0!important
	}
	.pl-l-5 {
		padding-left: 5px!important
	}
	.pl-l-10 {
		padding-left: 10px!important
	}
	.pl-l-15 {
		padding-left: 15px!important
	}
	.pl-l-20 {
		padding-left: 20px!important
	}
	.pl-l-25 {
		padding-left: 25px!important
	}
	.pl-l-30 {
		padding-left: 30px!important
	}
	.pl-l-35 {
		padding-left: 35px!important
	}
	.pl-l-40 {
		padding-left: 40px!important
	}
	.pl-l-45 {
		padding-left: 45px!important
	}
	.pl-l-50 {
		padding-left: 50px!important
	}
	.p-l-0 {
		padding: 0!important
	}
	.p-l-5 {
		padding: 5px!important
	}
	.p-l-10 {
		padding: 10px!important
	}
	.p-l-15 {
		padding: 15px!important
	}
	.p-l-20 {
		padding: 20px!important
	}
	.p-l-25 {
		padding: 25px!important
	}
	.p-l-30 {
		padding: 30px!important
	}
	.p-l-35 {
		padding: 35px!important
	}
	.p-l-40 {
		padding: 40px!important
	}
	.p-l-45 {
		padding: 45px!important
	}
	.p-l-50 {
		padding: 50px!important
	}
	.ptb-l-0 {
		padding-top: 0px!important;
		padding-bottom: 0px!important
	}
	.ptb-l-5 {
		padding-top: 5px!important;
		padding-bottom: 5px!important
	}
	.ptb-l-10 {
		padding-top: 10px!important;
		padding-bottom: 10px!important
	}
	.ptb-l-15 {
		padding-top: 15px!important;
		padding-bottom: 15px!important
	}
	.ptb-l-20 {
		padding-top: 20px!important;
		padding-bottom: 20px!important
	}
	.ptb-l-25 {
		padding-top: 25px!important;
		padding-bottom: 25px!important
	}
	.ptb-l-30 {
		padding-top: 30px!important;
		padding-bottom: 30px!important
	}
	.ptb-l-35 {
		padding-top: 35px!important;
		padding-bottom: 35px!important
	}
	.ptb-l-40 {
		padding-top: 40px!important;
		padding-bottom: 40px!important
	}
	.ptb-l-45 {
		padding-top: 45px!important;
		padding-bottom: 45px!important
	}
	.ptb-l-50 {
		padding-top: 50px!important;
		padding-bottom: 50px!important
	}
	.ptb-l-60 {
		padding-top: 60px!important;
		padding-bottom: 60px!important
	}
	.ptb-l-70 {
		padding-top: 70px!important;
		padding-bottom: 70px!important
	}
	.ptb-l-80 {
		padding-top: 80px!important;
		padding-bottom: 80px!important
	}
	.ptb-l-90 {
		padding-top: 90px!important;
		padding-bottom: 90px!important
	}
	.ptb-l-100 {
		padding-top: 100px!important;
		padding-bottom: 100px!important
	}
	.plr-l-0 {
		padding-left: 0px!important;
		padding-right: 0px!important
	}
	.plr-l-5 {
		padding-left: 5px!important;
		padding-right: 5px!important
	}
	.plr-l-10 {
		padding-left: 10px!important;
		padding-right: 10px!important
	}
	.plr-l-15 {
		padding-left: 15px!important;
		padding-right: 15px!important
	}
	.plr-l-20 {
		padding-left: 20px!important;
		padding-right: 20px!important
	}
	.plr-l-25 {
		padding-left: 25px!important;
		padding-right: 25px!important
	}
	.plr-l-30 {
		padding-left: 30px!important;
		padding-right: 30px!important
	}
	.plr-l-35 {
		padding-left: 35px!important;
		padding-right: 35px!important
	}
	.plr-l-40 {
		padding-left: 40px!important;
		padding-right: 40px!important
	}
	.plr-l-45 {
		padding-left: 45px!important;
		padding-right: 45px!important
	}
	.plr-l-50 {
		padding-left: 50px!important;
		padding-right: 50px!important
	}
	.plr-l-60 {
		padding-left: 60px!important;
		padding-right: 60px!important
	}
	.plr-l-70 {
		padding-left: 70px!important;
		padding-right: 70px!important
	}
	.plr-l-80 {
		padding-left: 80px!important;
		padding-right: 80px!important
	}
	.plr-l-90 {
		padding-left: 90px!important;
		padding-right: 90px!important
	}
	.plr-l-100 {
		padding-left: 100px!important;
		padding-right: 100px!important
	}
	h1, .h1 {
		font-size: 34px
	}
	h2, .h2 {
		font-size: 28px
	}
	h3, .h3 {
		font-size: 22px
	}
	h4, .h4 {
		font-size: 18px
	}
	h5, .h5 {
		font-size: 14px
	}
	h6, .h6 {
		font-size: 12px
	}
	.colp-l-0, .cp-l-0 {
		margin: 0!important
	}
	.colp-l-5, .cp-l-5 {
		margin: 0 -5px!important
	}
	.colp-l-10, .cp-l-10 {
		margin: 0 -10px!important
	}
	.colp-l-15, .cp-l-15 {
		margin: 0 -15px!important
	}
	.colp-l-20, .cp-l-20 {
		margin: 0 -20px!important
	}
	.colp-l-0 [class*="col-"], .cp-l-0 [class*="col-"] {
		padding: 0
	}
	.colp-l-5 [class*="col-"], .cp-l-5 [class*="col-"] {
		padding: 0 5px
	}
	.colp-l-10 [class*="col-"], .cp-l-10 [class*="col-"] {
		padding: 0 10px
	}
	.colp-l-15 [class*="col-"], .cp-l-15 [class*="col-"] {
		padding: 0 15px
	}
	.colp-l-20 [class*="col-"], .cp-l-20 [class*="col-"] {
		padding: 0 20px
	}
	.zi-l--1 {
		z-index: -1;
	}
	.zi-l-0 {
		z-index: 0;
	}
	.zi-l-1 {
		z-index: 1;
	}
	.zi-l-2 {
		z-index: 2;
	}
	.zi-l-3 {
		z-index: 3;
	}
	.zi-l-inf {
		z-index: 9999999999999;
	}
}
@media only screen and (max-width: 1199px) {
	.container, .container-p, .c, .c-p {
		width: 970px
	}
	.col-m-0 {
		width: 0%
	}
	.col-m-5 {
		width: 5%
	}
	.col-m-8 {
		width: 8.3333333%
	}
	.col-m-10 {
		width: 10%
	}
	.col-m-15 {
		width: 15%
	}
	.col-m-16, .col-m-17 {
		width: 16.6666667%
	}
	.col-m-20 {
		width: 20%
	}
	.col-m-25 {
		width: 25%
	}
	.col-m-30 {
		width: 30%
	}
	.col-m-33, .col-m-34 {
		width: 33.3333333%
	}
	.col-m-35 {
		width: 35%
	}
	.col-m-40 {
		width: 40%
	}
	.col-m-42 {
		width: 41.6666666%
	}
	.col-m-45 {
		width: 45%
	}
	.col-m-50 {
		width: 50%
	}
	.col-m-55 {
		width: 55%
	}
	.col-m-58 {
		width: 58.3333333%
	}
	.col-m-60 {
		width: 60%
	}
	.col-m-65 {
		width: 65%
	}
	.col-m-66, .col-m-67 {
		width: 66.6666666%
	}
	.col-m-70 {
		width: 70%
	}
	.col-m-75, .col-m-76 {
		width: 75%
	}
	.col-m-80 {
		width: 80%
	}
	.col-m-83, .col-m-84 {
		width: 83.3333333%
	}
	.col-m-85 {
		width: 85%
	}
	.col-m-90 {
		width: 90%
	}
	.col-m-92 {
		width: 91.6666667%
	}
	.col-m-95 {
		width: 95%
	}
	.col-m-100 {
		width: 100%
	}
	.stredges-m, .se-m {
		margin-left: -15px!important;
		margin-right: -15px!important
	}
	.stredges-m-0, .se-m-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.pe-m-auto, .pe-m-a {
		pointer-events: auto
	}
	.pe-m-none, .pe-m-n {
		pointer-events: none
	}
	.offset-m-0, .offset-m-none, .of-m-n, .of-m-0 {
		margin-left: 0!important
	}
	.push-m-0, .push-m-none, .push-m-n {
		left: 0!important
	}
	.pull-m-0, .pull-m-none, .pull-m-n {
		right: 0!important
	}
	.t-m-center, .t-m-c {
		text-align: center!important
	}
	.t-m-left, .t-m-l {
		text-align: left!important
	}
	.t-m-right, .t-m-r {
		text-align: right!important
	}
	.t-m-justify, .t-m-j {
		text-align: justify!important;
		-ms-text-align-last: justify!important;
		text-align-last: justify!important
	}
	.va-m-top, .va-m-t {
		vertical-align: middle
	}
	.va-m-bottom, .va-m-b {
		vertical-align: middle
	}
	.va-m-middle, .va-m-m {
		vertical-align: middle
	}
	.fd-m-row, .fd-m-r {
		-webkit-flex-direction: row;
		flex-direction: row
	}
	.fd-m-rowrs, .fd-m-rr {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.fd-m-col, .fd-m-c {
		-webkit-flex-direction: column;
		flex-direction: column
	}
	.fd-m-colrs, .fd-m-cr {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.fw-m-wrap, .fw-m-w {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.fw-m-nowrap, .fw-m-nw {
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.fw-m-wraprs, .fw-m-wr {
		-webkit-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse
	}
	.ff-m-normal, .ff-m-n {
		-webkit-flex-flow: row wrap;
		flex-flow: row wrap
	}
	.jc-m-start, .jc-m-s {
		-webkit-justify-content: flex-start;
		justify-content: flex-start
	}
	.jc-m-center, .jc-m-c {
		-webkit-justify-content: center;
		justify-content: center
	}
	.jc-m-end, .jc-m-e {
		-webkit-justify-content: flex-end;
		justify-content: flex-end
	}
	.jc-m-sbetween, .jc-m-sb {
		-webkit-justify-content: space-between;
		justify-content: space-between
	}
	.jc-m-saround, .jc-m-sa {
		-webkit-justify-content: space-around;
		justify-content: space-around
	}
	.jc-m-sevenly, .jc-m-se {
		-webkit-justify-content: space-evenly;
		justify-content: space-evenly
	}
	.ac-m-start, .ac-m-s {
		-webkit-align-content: flex-start;
		align-content: flex-start
	}
	.ac-m-center, .ac-m-c {
		-webkit-align-content: center;
		align-content: center
	}
	.ac-m-end, .ac-m-e {
		-webkit-align-content: flex-end;
		align-content: flex-end
	}
	.ac-m-stretch, .ac-m-str {
		-webkit-align-content: stretch;
		align-content: stretch
	}
	.ac-m-sbetween, .ac-m-sb {
		-webkit-align-content: space-between;
		align-content: space-between
	}
	.ac-m-saround, .ac-m-sa {
		-webkit-align-content: space-around;
		align-content: space-around
	}
	.ai-m-start, .ai-m-s {
		-webkit-align-items: flex-start;
		align-items: flex-start
	}
	.ai-m-center, .ai-m-c {
		-webkit-align-items: center;
		align-items: center
	}
	.ai-m-end, .ai-m-e {
		-webkit-align-items: flex-end;
		align-items: flex-end
	}
	.ai-m-base, .ai-m-bs {
		-webkit-align-items: baseline;
		align-items: baseline
	}
	.ai-m-stretch, .ai-m-str {
		-webkit-align-items: stretch;
		align-items: stretch
	}
	.as-m-start, .as-m-s {
		-webkit-align-self: flex-start;
		align-self: flex-start
	}
	.as-m-center, .as-m-c {
		-webkit-align-self: center;
		align-self: center
	}
	.as-m-end, .as-m-e {
		-webkit-align-self: flex-end;
		align-self: flex-end
	}
	.as-m-base, .as-m-bs {
		-webkit-align-self: baseline;
		align-self: baseline
	}
	.as-m-stretch, .as-m-str {
		-webkit-align-self: stretch;
		align-self: stretch
	}
	.order-m--1, .or-m--1 {
		order: -1
	}
	.order-m-0, .or-m-0 {
		order: 0
	}
	.order-m-1, .or-m-1 {
		order: 1
	}
	.lh-m-0 {
		line-height: 0
	}
	.lh-m-1 {
		line-height: 1
	}
	.ofw-m-h {
		overflow: hidden;
	}
	.ofwx-m-h {
		overflow-x: hidden;
	}
	.ofwy-m-h {
		overflow-x: hidden;
	}
	.ofw-m-a {
		overflow: auto;
	}
	.ofwx-m-a {
		overflow-x: auto;
	}
	.ofwy-m-a {
		overflow-x: auto;
	}
	.ofw-m-s {
		overflow: scroll;
	}
	.ofwx-m-s {
		overflow-x: scroll;
	}
	.ofwy-m-s {
		overflow-x: scroll;
	}
	.h-m-inherit, .h-m-i {
		height: inherit
	}
	.w-m-inherit, .w-m-i {
		width: inherit
	}
	/*EXPERIMENTAL VARS*/
	.m-m-1 {
		margin: var(--margin-1, 5px)!important
	}
	.m-m-2 {
		margin: var(--margin-2, 15px)!important
	}
	.m-m-3 {
		margin: var(--margin-3, 20px)!important
	}
	.m-m-4 {
		margin: var(--margin-4, 30px)!important
	}
	.m-m-5 {
		margin: var(--margin-5, 50px)!important
	}
	.mt-m-1 {
		margin-top: var(--margin-1, 5px)!important
	}
	.mt-m-2 {
		margin-top: var(--margin-2, 15px)!important
	}
	.mt-m-3 {
		margin-top: var(--margin-3, 20px)!important
	}
	.mt-m-4 {
		margin-top: var(--margin-4, 30px)!important
	}
	.mt-m-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mr-m-1 {
		margin-right: var(--margin-1, 5px)!important
	}
	.mr-m-2 {
		margin-right: var(--margin-2, 15px)!important
	}
	.mr-m-3 {
		margin-right: var(--margin-3, 20px)!important
	}
	.mr-m-4 {
		margin-right: var(--margin-4, 30px)!important
	}
	.mr-m-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mb-m-1 {
		margin-bottom: var(--margin-1, 5px)!important
	}
	.mb-m-2 {
		margin-bottom: var(--margin-2, 15px)!important
	}
	.mb-m-3 {
		margin-bottom: var(--margin-3, 20px)!important
	}
	.mb-m-4 {
		margin-bottom: var(--margin-4, 30px)!important
	}
	.mb-m-5 {
		margin-bottom: var(--margin-5, 50px)!important
	}
	.ml-m-1 {
		margin-left: var(--margin-1, 5px)!important
	}
	.ml-m-2 {
		margin-left: var(--margin-2, 15px)!important
	}
	.ml-m-3 {
		margin-left: var(--margin-3, 20px)!important
	}
	.ml-m-4 {
		margin-left: var(--margin-4, 30px)!important
	}
	.ml-m-5 {
		margin-left: var(--margin-5, 50px)!important
	}
	/*EXPERIMENTAL VARS*/
	.m-m-0 {
		margin: 0!important
	}
	.mt-m-0 {
		margin-top: 0!important
	}
	.mt-m-5 {
		margin-top: 5px!important
	}
	.mt-m-10 {
		margin-top: 10px!important
	}
	.mt-m-15 {
		margin-top: 15px!important
	}
	.mt-m-20 {
		margin-top: 20px!important
	}
	.mt-m-25 {
		margin-top: 25px!important
	}
	.mt-m-30 {
		margin-top: 30px!important
	}
	.mt-m-35 {
		margin-top: 35px!important
	}
	.mt-m-40 {
		margin-top: 40px!important
	}
	.mt-m-45 {
		margin-top: 45px!important
	}
	.mt-m-50 {
		margin-top: 50px!important
	}
	.mr-m-0 {
		margin-right: 0!important
	}
	.mr-m-5 {
		margin-right: 5px!important
	}
	.mr-m-10 {
		margin-right: 10px!important
	}
	.mr-m-15 {
		margin-right: 15px!important
	}
	.mr-m-20 {
		margin-right: 20px!important
	}
	.mr-m-25 {
		margin-right: 25px!important
	}
	.mr-m-30 {
		margin-right: 30px!important
	}
	.mr-m-35 {
		margin-right: 35px!important
	}
	.mr-m-40 {
		margin-right: 40px!important
	}
	.mr-m-45 {
		margin-right: 45px!important
	}
	.mr-m-50 {
		margin-right: 50px!important
	}
	.mb-m-0 {
		margin-bottom: 0!important
	}
	.mb-m-5 {
		margin-bottom: 5px!important
	}
	.mb-m-10 {
		margin-bottom: 10px!important
	}
	.mb-m-15 {
		margin-bottom: 15px!important
	}
	.mb-m-20 {
		margin-bottom: 20px!important
	}
	.mb-m-25 {
		margin-bottom: 25px!important
	}
	.mb-m-30 {
		margin-bottom: 30px!important
	}
	.mb-m-35 {
		margin-bottom: 35px!important
	}
	.mb-m-40 {
		margin-bottom: 40px!important
	}
	.mb-m-45 {
		margin-bottom: 45px!important
	}
	.mb-m-50 {
		margin-bottom: 50px!important
	}
	.ml-m-0 {
		margin-left: 0!important
	}
	.ml-m-5 {
		margin-left: 5px!important
	}
	.ml-m-10 {
		margin-left: 10px!important
	}
	.ml-m-15 {
		margin-left: 15px!important
	}
	.ml-m-20 {
		margin-left: 20px!important
	}
	.ml-m-25 {
		margin-left: 25px!important
	}
	.ml-m-30 {
		margin-left: 30px!important
	}
	.ml-m-35 {
		margin-left: 35px!important
	}
	.ml-m-40 {
		margin-left: 40px!important
	}
	.ml-m-45 {
		margin-left: 45px!important
	}
	.ml-m-50 {
		margin-left: 50px!important
	}
	.mtb-m-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.mtb-m-5 {
		margin-top: 5px!important;
		margin-bottom: 5px!important
	}
	.mtb-m-10 {
		margin-top: 10px!important;
		margin-bottom: 10px!important
	}
	.mtb-m-15 {
		margin-top: 15px!important;
		margin-bottom: 15px!important
	}
	.mtb-m-20 {
		margin-top: 20px!important;
		margin-bottom: 20px!important
	}
	.mtb-m-25 {
		margin-top: 25px!important;
		margin-bottom: 25px!important
	}
	.mtb-m-30 {
		margin-top: 30px!important;
		margin-bottom: 30px!important
	}
	.mtb-m-35 {
		margin-top: 35px!important;
		margin-bottom: 35px!important
	}
	.mtb-m-40 {
		margin-top: 40px!important;
		margin-bottom: 40px!important
	}
	.mtb-m-45 {
		margin-top: 45px!important;
		margin-bottom: 45px!important
	}
	.mtb-m-50 {
		margin-top: 50px!important;
		margin-bottom: 50px!important
	}
	.mtb-m-60 {
		margin-top: 60px!important;
		margin-bottom: 60px!important
	}
	.mtb-m-70 {
		margin-top: 70px!important;
		margin-bottom: 70px!important
	}
	.mtb-m-80 {
		margin-top: 80px!important;
		margin-bottom: 80px!important
	}
	.mtb-m-90 {
		margin-top: 90px!important;
		margin-bottom: 90px!important
	}
	.mtb-m-100 {
		margin-top: 100px!important;
		margin-bottom: 100px!important
	}
	.mlr-m-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.mlr-m-5 {
		margin-left: 5px!important;
		margin-right: 5px!important
	}
	.mlr-m-10 {
		margin-left: 10px!important;
		margin-right: 10px!important
	}
	.mlr-m-15 {
		margin-left: 15px!important;
		margin-right: 15px!important
	}
	.mlr-m-20 {
		margin-left: 20px!important;
		margin-right: 20px!important
	}
	.mlr-m-25 {
		margin-left: 25px!important;
		margin-right: 25px!important
	}
	.mlr-m-30 {
		margin-left: 30px!important;
		margin-right: 30px!important
	}
	.mlr-m-35 {
		margin-left: 35px!important;
		margin-right: 35px!important
	}
	.mlr-m-40 {
		margin-left: 40px!important;
		margin-right: 40px!important
	}
	.mlr-m-45 {
		margin-left: 45px!important;
		margin-right: 45px!important
	}
	.mlr-m-50 {
		margin-left: 50px!important;
		margin-right: 50px!important
	}
	.mlr-m-60 {
		margin-left: 60px!important;
		margin-right: 60px!important
	}
	.mlr-m-70 {
		margin-left: 70px!important;
		margin-right: 70px!important
	}
	.mlr-m-80 {
		margin-left: 80px!important;
		margin-right: 80px!important
	}
	.mlr-m-90 {
		margin-left: 90px!important;
		margin-right: 90px!important
	}
	.mlr-m-100 {
		margin-left: 100px!important;
		margin-right: 100px!important
	}
	.p-m-0 {
		padding: 0!important
	}
	.pt-m-0 {
		padding-top: 0!important
	}
	.pt-m-5 {
		padding-top: 5px!important
	}
	.pt-m-10 {
		padding-top: 10px!important
	}
	.pt-m-15 {
		padding-top: 15px!important
	}
	.pt-m-20 {
		padding-top: 20px!important
	}
	.pt-m-25 {
		padding-top: 25px!important
	}
	.pt-m-30 {
		padding-top: 30px!important
	}
	.pt-m-35 {
		padding-top: 35px!important
	}
	.pt-m-40 {
		padding-top: 40px!important
	}
	.pt-m-45 {
		padding-top: 45px!important
	}
	.pt-m-50 {
		padding-top: 50px!important
	}
	.pr-m-0 {
		padding-right: 0!important
	}
	.pr-m-5 {
		padding-right: 5px!important
	}
	.pr-m-10 {
		padding-right: 10px!important
	}
	.pr-m-15 {
		padding-right: 15px!important
	}
	.pr-m-20 {
		padding-right: 20px!important
	}
	.pr-m-25 {
		padding-right: 25px!important
	}
	.pr-m-30 {
		padding-right: 30px!important
	}
	.pr-m-35 {
		padding-right: 35px!important
	}
	.pr-m-40 {
		padding-right: 40px!important
	}
	.pr-m-45 {
		padding-right: 45px!important
	}
	.pr-m-50 {
		padding-right: 50px!important
	}
	.pb-m-0 {
		padding-bottom: 0!important
	}
	.pb-m-5 {
		padding-bottom: 5px!important
	}
	.pb-m-10 {
		padding-bottom: 10px!important
	}
	.pb-m-15 {
		padding-bottom: 15px!important
	}
	.pb-m-20 {
		padding-bottom: 20px!important
	}
	.pb-m-25 {
		padding-bottom: 25px!important
	}
	.pb-m-30 {
		padding-bottom: 30px!important
	}
	.pb-m-35 {
		padding-bottom: 35px!important
	}
	.pb-m-40 {
		padding-bottom: 40px!important
	}
	.pb-m-45 {
		padding-bottom: 45px!important
	}
	.pb-m-50 {
		padding-bottom: 50px!important
	}
	.pl-m-0 {
		padding-left: 0!important
	}
	.pl-m-5 {
		padding-left: 5px!important
	}
	.pl-m-10 {
		padding-left: 10px!important
	}
	.pl-m-15 {
		padding-left: 15px!important
	}
	.pl-m-20 {
		padding-left: 20px!important
	}
	.pl-m-25 {
		padding-left: 25px!important
	}
	.pl-m-30 {
		padding-left: 30px!important
	}
	.pl-m-35 {
		padding-left: 35px!important
	}
	.pl-m-40 {
		padding-left: 40px!important
	}
	.pl-m-45 {
		padding-left: 45px!important
	}
	.pl-m-50 {
		padding-left: 50px!important
	}
	.p-m-0 {
		padding: 0!important
	}
	.p-m-5 {
		padding: 5px!important
	}
	.p-m-10 {
		padding: 10px!important
	}
	.p-m-15 {
		padding: 15px!important
	}
	.p-m-20 {
		padding: 20px!important
	}
	.p-m-25 {
		padding: 25px!important
	}
	.p-m-30 {
		padding: 30px!important
	}
	.p-m-35 {
		padding: 35px!important
	}
	.p-m-40 {
		padding: 40px!important
	}
	.p-m-45 {
		padding: 45px!important
	}
	.p-m-50 {
		padding: 50px!important
	}
	.ptb-m-0 {
		padding-top: 0px!important;
		padding-bottom: 0px!important
	}
	.ptb-m-5 {
		padding-top: 5px!important;
		padding-bottom: 5px!important
	}
	.ptb-m-10 {
		padding-top: 10px!important;
		padding-bottom: 10px!important
	}
	.ptb-m-15 {
		padding-top: 15px!important;
		padding-bottom: 15px!important
	}
	.ptb-m-20 {
		padding-top: 20px!important;
		padding-bottom: 20px!important
	}
	.ptb-m-25 {
		padding-top: 25px!important;
		padding-bottom: 25px!important
	}
	.ptb-m-30 {
		padding-top: 30px!important;
		padding-bottom: 30px!important
	}
	.ptb-m-35 {
		padding-top: 35px!important;
		padding-bottom: 35px!important
	}
	.ptb-m-40 {
		padding-top: 40px!important;
		padding-bottom: 40px!important
	}
	.ptb-m-45 {
		padding-top: 45px!important;
		padding-bottom: 45px!important
	}
	.ptb-m-50 {
		padding-top: 50px!important;
		padding-bottom: 50px!important
	}
	.ptb-m-60 {
		padding-top: 60px!important;
		padding-bottom: 60px!important
	}
	.ptb-m-70 {
		padding-top: 70px!important;
		padding-bottom: 70px!important
	}
	.ptb-m-80 {
		padding-top: 80px!important;
		padding-bottom: 80px!important
	}
	.ptb-m-90 {
		padding-top: 90px!important;
		padding-bottom: 90px!important
	}
	.ptb-m-100 {
		padding-top: 100px!important;
		padding-bottom: 100px!important
	}
	.plr-m-0 {
		padding-left: 0px!important;
		padding-right: 0px!important
	}
	.plr-m-5 {
		padding-left: 5px!important;
		padding-right: 5px!important
	}
	.plr-m-10 {
		padding-left: 10px!important;
		padding-right: 10px!important
	}
	.plr-m-15 {
		padding-left: 15px!important;
		padding-right: 15px!important
	}
	.plr-m-20 {
		padding-left: 20px!important;
		padding-right: 20px!important
	}
	.plr-m-25 {
		padding-left: 25px!important;
		padding-right: 25px!important
	}
	.plr-m-30 {
		padding-left: 30px!important;
		padding-right: 30px!important
	}
	.plr-m-35 {
		padding-left: 35px!important;
		padding-right: 35px!important
	}
	.plr-m-40 {
		padding-left: 40px!important;
		padding-right: 40px!important
	}
	.plr-m-45 {
		padding-left: 45px!important;
		padding-right: 45px!important
	}
	.plr-m-50 {
		padding-left: 50px!important;
		padding-right: 50px!important
	}
	.plr-m-60 {
		padding-left: 60px!important;
		padding-right: 60px!important
	}
	.plr-m-70 {
		padding-left: 70px!important;
		padding-right: 70px!important
	}
	.plr-m-80 {
		padding-left: 80px!important;
		padding-right: 80px!important
	}
	.plr-m-90 {
		padding-left: 90px!important;
		padding-right: 90px!important
	}
	.plr-m-100 {
		padding-left: 100px!important;
		padding-right: 100px!important
	}
	h1, .h1 {
		font-size: 34px
	}
	h2, .h2 {
		font-size: 28px
	}
	h3, .h3 {
		font-size: 22px
	}
	h4, .h4 {
		font-size: 18px
	}
	h5, .h5 {
		font-size: 14px
	}
	h6, .h6 {
		font-size: 12px
	}
	.colp-m-0, .cp-m-0 {
		margin: 0!important
	}
	.colp-m-5, .cp-m-5 {
		margin: 0 -5px!important
	}
	.colp-m-10, .cp-m-10 {
		margin: 0 -10px!important
	}
	.colp-m-15, .cp-m-15 {
		margin: 0 -15px!important
	}
	.colp-m-20, .cp-m-20 {
		margin: 0 -20px!important
	}
	.colp-m-0 [class*="col-"], .cp-m-0 [class*="col-"] {
		padding: 0
	}
	.colp-m-5 [class*="col-"], .cp-m-5 [class*="col-"] {
		padding: 0 5px
	}
	.colp-m-10 [class*="col-"], .cp-m-10 [class*="col-"] {
		padding: 0 10px
	}
	.colp-m-15 [class*="col-"], .cp-m-15 [class*="col-"] {
		padding: 0 15px
	}
	.colp-m-20 [class*="col-"], .cp-m-20 [class*="col-"] {
		padding: 0 20px
	}
	.zi-m--1 {
		z-index: -1;
	}
	.zi-m-0 {
		z-index: 0;
	}
	.zi-m-1 {
		z-index: 1;
	}
	.zi-m-2 {
		z-index: 2;
	}
	.zi-m-3 {
		z-index: 3;
	}
	.zi-m-inf {
		z-index: 9999999999999;
	}
}
@media only screen and (max-width: 991px) {
	.container, .container-p, .c, .c-p {
		width: 750px
	}
	.col-s-0 {
		width: 0%
	}
	.col-s-5 {
		width: 5%
	}
	.col-s-8 {
		width: 8.3333333%
	}
	.col-s-10 {
		width: 10%
	}
	.col-s-15 {
		width: 15%
	}
	.col-s-16, .col-s-17 {
		width: 16.6666667%
	}
	.col-s-20 {
		width: 20%
	}
	.col-s-25 {
		width: 25%
	}
	.col-s-30 {
		width: 30%
	}
	.col-s-33, .col-s-34 {
		width: 33.3333333%
	}
	.col-s-35 {
		width: 35%
	}
	.col-s-40 {
		width: 40%
	}
	.col-s-42 {
		width: 41.6666666%
	}
	.col-s-45 {
		width: 45%
	}
	.col-s-50 {
		width: 50%
	}
	.col-s-55 {
		width: 55%
	}
	.col-s-58 {
		width: 58.3333333%
	}
	.col-s-60 {
		width: 60%
	}
	.col-s-65 {
		width: 65%
	}
	.col-s-66, .col-s-67 {
		width: 66.6666666%
	}
	.col-s-70 {
		width: 70%
	}
	.col-s-75, .col-s-76 {
		width: 75%
	}
	.col-s-80 {
		width: 80%
	}
	.col-s-83, .col-s-84 {
		width: 83.3333333%
	}
	.col-s-85 {
		width: 85%
	}
	.col-s-90 {
		width: 90%
	}
	.col-s-92 {
		width: 91.6666667%
	}
	.col-s-95 {
		width: 95%
	}
	.col-s-100 {
		width: 100%
	}
	.stredges-s, .se-s {
		margin-left: -15px!important;
		margin-right: -15px!important
	}
	.stredges-s-0, .se-s-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.pe-s-auto, .pe-s-a {
		pointer-events: auto
	}
	.pe-s-none, .pe-s-n {
		pointer-events: none
	}
	.offset-s-0, .offset-s-none, .of-s-n, .of-s-0 {
		margin-left: 0!important
	}
	.push-s-0, .push-s-none, .push-s-n {
		left: 0!important
	}
	.pull-s-0, .pull-s-none, .pull-s-n {
		right: 0!important
	}
	.t-s-center, .t-s-c {
		text-align: center!important
	}
	.t-s-left, .t-s-l {
		text-align: left!important
	}
	.t-s-right, .t-s-r {
		text-align: right!important
	}
	.t-s-justify, .t-s-j {
		text-align: justify!important;
		-ms-text-align-last: justify!important;
		text-align-last: justify!important
	}
	.va-s-top, .va-s-t {
		vertical-align: middle
	}
	.va-s-bottom, .va-s-b {
		vertical-align: middle
	}
	.va-s-middle, .va-s-m {
		vertical-align: middle
	}
	.fd-s-row, .fd-s-r {
		-webkit-flex-direction: row;
		flex-direction: row
	}
	.fd-s-rowrs, .fd-s-rr {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.fd-s-col, .fd-s-c {
		-webkit-flex-direction: column;
		flex-direction: column
	}
	.fd-s-colrs, .fd-s-cr {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.fw-s-wrap, .fw-s-w {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.fw-s-nowrap, .fw-s-nw {
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.fw-s-wraprs, .fw-s-wr {
		-webkit-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse
	}
	.ff-s-normal, .ff-s-n {
		-webkit-flex-flow: row wrap;
		flex-flow: row wrap
	}
	.jc-s-start, .jc-s-s {
		-webkit-justify-content: flex-start;
		justify-content: flex-start
	}
	.jc-s-center, .jc-s-c {
		-webkit-justify-content: center;
		justify-content: center
	}
	.jc-s-end, .jc-s-e {
		-webkit-justify-content: flex-end;
		justify-content: flex-end
	}
	.jc-s-sbetween, .jc-s-sb {
		-webkit-justify-content: space-between;
		justify-content: space-between
	}
	.h-s-saround, .h-s-sa, .jc-s-saround, .jc-s-sa {
		-webkit-justify-content: space-around;
		justify-content: space-around
	}
	.jc-s-sevenly, .jc-s-se {
		-webkit-justify-content: space-evenly;
		justify-content: space-evenly
	}
	.ac-s-start, .ac-s-s {
		-webkit-align-content: flex-start;
		align-content: flex-start
	}
	.ac-s-center, .ac-s-c {
		-webkit-align-content: center;
		align-content: center
	}
	.ac-s-end, .ac-s-e {
		-webkit-align-content: flex-end;
		align-content: flex-end
	}
	.ac-s-stretch, .ac-s-str {
		-webkit-align-content: stretch;
		align-content: stretch
	}
	.ac-s-sbetween, .ac-s-sb {
		-webkit-align-content: space-between;
		align-content: space-between
	}
	.ac-s-saround, .ac-s-sa {
		-webkit-align-content: space-around;
		align-content: space-around
	}
	.ai-s-start, .ai-s-s {
		-webkit-align-items: flex-start;
		align-items: flex-start
	}
	.ai-s-center, .ai-s-c {
		-webkit-align-items: center;
		align-items: center
	}
	.ai-s-end, .ai-s-e {
		-webkit-align-items: flex-end;
		align-items: flex-end
	}
	.ai-s-base, .ai-s-bs {
		-webkit-align-items: baseline;
		align-items: baseline
	}
	.ai-s-stretch, .ai-s-str {
		-webkit-align-items: stretch;
		align-items: stretch
	}
	.as-s-start, .as-s-s {
		-webkit-align-self: flex-start;
		align-self: flex-start
	}
	.as-s-center, .as-s-c {
		-webkit-align-self: center;
		align-self: center
	}
	.as-s-end, .as-s-e {
		-webkit-align-self: flex-end;
		align-self: flex-end
	}
	.as-s-base, .as-s-bs {
		-webkit-align-self: baseline;
		align-self: baseline
	}
	.as-s-stretch, .as-s-str {
		-webkit-align-self: stretch;
		align-self: stretch
	}
	.order-s--1, .or-s--1 {
		order: -1
	}
	.order-s-0, .or-s-0 {
		order: 0
	}
	.order-s-1, .or-s-1 {
		order: 1
	}
	.lh-s-0 {
		line-height: 0
	}
	.lh-s-1 {
		line-height: 1
	}
	.ofw-s-h {
		overflow: hidden;
	}
	.ofwx-s-h {
		overflow-x: hidden;
	}
	.ofwy-s-h {
		overflow-x: hidden;
	}
	.ofw-s-a {
		overflow: auto;
	}
	.ofwx-s-a {
		overflow-x: auto;
	}
	.ofwy-s-a {
		overflow-x: auto;
	}
	.ofw-s-s {
		overflow: scroll;
	}
	.ofwx-s-s {
		overflow-x: scroll;
	}
	.ofwy-s-s {
		overflow-x: scroll;
	}
	.h-s-inherit, .h-s-i {
		height: inherit
	}
	.w-s-inherit, .w-s-i {
		width: inherit
	}
	/*EXPERIMENTAL VARS*/
	.m-s-1 {
		margin: var(--margin-1, 5px)!important
	}
	.m-s-2 {
		margin: var(--margin-2, 15px)!important
	}
	.m-s-3 {
		margin: var(--margin-3, 20px)!important
	}
	.m-s-4 {
		margin: var(--margin-4, 30px)!important
	}
	.m-s-5 {
		margin: var(--margin-5, 50px)!important
	}
	.mt-s-1 {
		margin-top: var(--margin-1, 5px)!important
	}
	.mt-s-2 {
		margin-top: var(--margin-2, 15px)!important
	}
	.mt-s-3 {
		margin-top: var(--margin-3, 20px)!important
	}
	.mt-s-4 {
		margin-top: var(--margin-4, 30px)!important
	}
	.mt-s-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mr-s-1 {
		margin-right: var(--margin-1, 5px)!important
	}
	.mr-s-2 {
		margin-right: var(--margin-2, 15px)!important
	}
	.mr-s-3 {
		margin-right: var(--margin-3, 20px)!important
	}
	.mr-s-4 {
		margin-right: var(--margin-4, 30px)!important
	}
	.mr-s-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mb-s-1 {
		margin-bottom: var(--margin-1, 5px)!important
	}
	.mb-s-2 {
		margin-bottom: var(--margin-2, 15px)!important
	}
	.mb-s-3 {
		margin-bottom: var(--margin-3, 20px)!important
	}
	.mb-s-4 {
		margin-bottom: var(--margin-4, 30px)!important
	}
	.mb-s-5 {
		margin-bottom: var(--margin-5, 50px)!important
	}
	.ml-s-1 {
		margin-left: var(--margin-1, 5px)!important
	}
	.ml-s-2 {
		margin-left: var(--margin-2, 15px)!important
	}
	.ml-s-3 {
		margin-left: var(--margin-3, 20px)!important
	}
	.ml-s-4 {
		margin-left: var(--margin-4, 30px)!important
	}
	.ml-s-5 {
		margin-left: var(--margin-5, 50px)!important
	}
	/*EXPERIMENTAL VARS*/
	.m-s-0 {
		margin: 0!important
	}
	.mt-s-0 {
		margin-top: 0!important
	}
	.mt-s-5 {
		margin-top: 5px!important
	}
	.mt-s-10 {
		margin-top: 10px!important
	}
	.mt-s-15 {
		margin-top: 15px!important
	}
	.mt-s-20 {
		margin-top: 20px!important
	}
	.mt-s-25 {
		margin-top: 25px!important
	}
	.mt-s-30 {
		margin-top: 30px!important
	}
	.mt-s-35 {
		margin-top: 35px!important
	}
	.mt-s-40 {
		margin-top: 40px!important
	}
	.mt-s-45 {
		margin-top: 45px!important
	}
	.mt-s-50 {
		margin-top: 50px!important
	}
	.mr-s-0 {
		margin-right: 0!important
	}
	.mr-s-5 {
		margin-right: 5px!important
	}
	.mr-s-10 {
		margin-right: 10px!important
	}
	.mr-s-15 {
		margin-right: 15px!important
	}
	.mr-s-20 {
		margin-right: 20px!important
	}
	.mr-s-25 {
		margin-right: 25px!important
	}
	.mr-s-30 {
		margin-right: 30px!important
	}
	.mr-s-35 {
		margin-right: 35px!important
	}
	.mr-s-40 {
		margin-right: 40px!important
	}
	.mr-s-45 {
		margin-right: 45px!important
	}
	.mr-s-50 {
		margin-right: 50px!important
	}
	.mb-s-0 {
		margin-bottom: 0!important
	}
	.mb-s-5 {
		margin-bottom: 5px!important
	}
	.mb-s-10 {
		margin-bottom: 10px!important
	}
	.mb-s-15 {
		margin-bottom: 15px!important
	}
	.mb-s-20 {
		margin-bottom: 20px!important
	}
	.mb-s-25 {
		margin-bottom: 25px!important
	}
	.mb-s-30 {
		margin-bottom: 30px!important
	}
	.mb-s-35 {
		margin-bottom: 35px!important
	}
	.mb-s-40 {
		margin-bottom: 40px!important
	}
	.mb-s-45 {
		margin-bottom: 45px!important
	}
	.mb-s-50 {
		margin-bottom: 50px!important
	}
	.ml-s-0 {
		margin-left: 0!important
	}
	.ml-s-5 {
		margin-left: 5px!important
	}
	.ml-s-10 {
		margin-left: 10px!important
	}
	.ml-s-15 {
		margin-left: 15px!important
	}
	.ml-s-20 {
		margin-left: 20px!important
	}
	.ml-s-25 {
		margin-left: 25px!important
	}
	.ml-s-30 {
		margin-left: 30px!important
	}
	.ml-s-35 {
		margin-left: 35px!important
	}
	.ml-s-40 {
		margin-left: 40px!important
	}
	.ml-s-45 {
		margin-left: 45px!important
	}
	.ml-s-50 {
		margin-left: 50px!important
	}
	.mtb-s-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.mtb-s-5 {
		margin-top: 5px!important;
		margin-bottom: 5px!important
	}
	.mtb-s-10 {
		margin-top: 10px!important;
		margin-bottom: 10px!important
	}
	.mtb-s-15 {
		margin-top: 15px!important;
		margin-bottom: 15px!important
	}
	.mtb-s-20 {
		margin-top: 20px!important;
		margin-bottom: 20px!important
	}
	.mtb-s-25 {
		margin-top: 25px!important;
		margin-bottom: 25px!important
	}
	.mtb-s-30 {
		margin-top: 30px!important;
		margin-bottom: 30px!important
	}
	.mtb-s-35 {
		margin-top: 35px!important;
		margin-bottom: 35px!important
	}
	.mtb-s-40 {
		margin-top: 40px!important;
		margin-bottom: 40px!important
	}
	.mtb-s-45 {
		margin-top: 45px!important;
		margin-bottom: 45px!important
	}
	.mtb-s-50 {
		margin-top: 50px!important;
		margin-bottom: 50px!important
	}
	.mtb-s-60 {
		margin-top: 60px!important;
		margin-bottom: 60px!important
	}
	.mtb-s-70 {
		margin-top: 70px!important;
		margin-bottom: 70px!important
	}
	.mtb-s-80 {
		margin-top: 80px!important;
		margin-bottom: 80px!important
	}
	.mtb-s-90 {
		margin-top: 90px!important;
		margin-bottom: 90px!important
	}
	.mtb-s-100 {
		margin-top: 100px!important;
		margin-bottom: 100px!important
	}
	.mlr-s-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.mlr-s-5 {
		margin-left: 5px!important;
		margin-right: 5px!important
	}
	.mlr-s-10 {
		margin-left: 10px!important;
		margin-right: 10px!important
	}
	.mlr-s-15 {
		margin-left: 15px!important;
		margin-right: 15px!important
	}
	.mlr-s-20 {
		margin-left: 20px!important;
		margin-right: 20px!important
	}
	.mlr-s-25 {
		margin-left: 25px!important;
		margin-right: 25px!important
	}
	.mlr-s-30 {
		margin-left: 30px!important;
		margin-right: 30px!important
	}
	.mlr-s-35 {
		margin-left: 35px!important;
		margin-right: 35px!important
	}
	.mlr-s-40 {
		margin-left: 40px!important;
		margin-right: 40px!important
	}
	.mlr-s-45 {
		margin-left: 45px!important;
		margin-right: 45px!important
	}
	.mlr-s-50 {
		margin-left: 50px!important;
		margin-right: 50px!important
	}
	.mlr-s-60 {
		margin-left: 60px!important;
		margin-right: 60px!important
	}
	.mlr-s-70 {
		margin-left: 70px!important;
		margin-right: 70px!important
	}
	.mlr-s-80 {
		margin-left: 80px!important;
		margin-right: 80px!important
	}
	.mlr-s-90 {
		margin-left: 90px!important;
		margin-right: 90px!important
	}
	.mlr-s-100 {
		margin-left: 100px!important;
		margin-right: 100px!important
	}
	.p-s-0 {
		padding: 0!important
	}
	.pt-s-0 {
		padding-top: 0!important
	}
	.pt-s-5 {
		padding-top: 5px!important
	}
	.pt-s-10 {
		padding-top: 10px!important
	}
	.pt-s-15 {
		padding-top: 15px!important
	}
	.pt-s-20 {
		padding-top: 20px!important
	}
	.pt-s-25 {
		padding-top: 25px!important
	}
	.pt-s-30 {
		padding-top: 30px!important
	}
	.pt-s-35 {
		padding-top: 35px!important
	}
	.pt-s-40 {
		padding-top: 40px!important
	}
	.pt-s-45 {
		padding-top: 45px!important
	}
	.pt-s-50 {
		padding-top: 50px!important
	}
	.pr-s-0 {
		padding-right: 0!important
	}
	.pr-s-5 {
		padding-right: 5px!important
	}
	.pr-s-10 {
		padding-right: 10px!important
	}
	.pr-s-15 {
		padding-right: 15px!important
	}
	.pr-s-20 {
		padding-right: 20px!important
	}
	.pr-s-25 {
		padding-right: 25px!important
	}
	.pr-s-30 {
		padding-right: 30px!important
	}
	.pr-s-35 {
		padding-right: 35px!important
	}
	.pr-s-40 {
		padding-right: 40px!important
	}
	.pr-s-45 {
		padding-right: 45px!important
	}
	.pr-s-50 {
		padding-right: 50px!important
	}
	.pb-s-0 {
		padding-bottom: 0!important
	}
	.pb-s-5 {
		padding-bottom: 5px!important
	}
	.pb-s-10 {
		padding-bottom: 10px!important
	}
	.pb-s-15 {
		padding-bottom: 15px!important
	}
	.pb-s-20 {
		padding-bottom: 20px!important
	}
	.pb-s-25 {
		padding-bottom: 25px!important
	}
	.pb-s-30 {
		padding-bottom: 30px!important
	}
	.pb-s-35 {
		padding-bottom: 35px!important
	}
	.pb-s-40 {
		padding-bottom: 40px!important
	}
	.pb-s-45 {
		padding-bottom: 45px!important
	}
	.pb-s-50 {
		padding-bottom: 50px!important
	}
	.pl-s-0 {
		padding-left: 0!important
	}
	.pl-s-5 {
		padding-left: 5px!important
	}
	.pl-s-10 {
		padding-left: 10px!important
	}
	.pl-s-15 {
		padding-left: 15px!important
	}
	.pl-s-20 {
		padding-left: 20px!important
	}
	.pl-s-25 {
		padding-left: 25px!important
	}
	.pl-s-30 {
		padding-left: 30px!important
	}
	.pl-s-35 {
		padding-left: 35px!important
	}
	.pl-s-40 {
		padding-left: 40px!important
	}
	.pl-s-45 {
		padding-left: 45px!important
	}
	.pl-s-50 {
		padding-left: 50px!important
	}
	.p-s-0 {
		padding: 0!important
	}
	.p-s-5 {
		padding: 5px!important
	}
	.p-s-10 {
		padding: 10px!important
	}
	.p-s-15 {
		padding: 15px!important
	}
	.p-s-20 {
		padding: 20px!important
	}
	.p-s-25 {
		padding: 25px!important
	}
	.p-s-30 {
		padding: 30px!important
	}
	.p-s-35 {
		padding: 35px!important
	}
	.p-s-40 {
		padding: 40px!important
	}
	.p-s-45 {
		padding: 45px!important
	}
	.p-s-50 {
		padding: 50px!important
	}
	.ptb-s-0 {
		padding-top: 0px!important;
		padding-bottom: 0px!important
	}
	.ptb-s-5 {
		padding-top: 5px!important;
		padding-bottom: 5px!important
	}
	.ptb-s-10 {
		padding-top: 10px!important;
		padding-bottom: 10px!important
	}
	.ptb-s-15 {
		padding-top: 15px!important;
		padding-bottom: 15px!important
	}
	.ptb-s-20 {
		padding-top: 20px!important;
		padding-bottom: 20px!important
	}
	.ptb-s-25 {
		padding-top: 25px!important;
		padding-bottom: 25px!important
	}
	.ptb-s-30 {
		padding-top: 30px!important;
		padding-bottom: 30px!important
	}
	.ptb-s-35 {
		padding-top: 35px!important;
		padding-bottom: 35px!important
	}
	.ptb-s-40 {
		padding-top: 40px!important;
		padding-bottom: 40px!important
	}
	.ptb-s-45 {
		padding-top: 45px!important;
		padding-bottom: 45px!important
	}
	.ptb-s-50 {
		padding-top: 50px!important;
		padding-bottom: 50px!important
	}
	.ptb-s-60 {
		padding-top: 60px!important;
		padding-bottom: 60px!important
	}
	.ptb-s-70 {
		padding-top: 70px!important;
		padding-bottom: 70px!important
	}
	.ptb-s-80 {
		padding-top: 80px!important;
		padding-bottom: 80px!important
	}
	.ptb-s-90 {
		padding-top: 90px!important;
		padding-bottom: 90px!important
	}
	.ptb-s-100 {
		padding-top: 100px!important;
		padding-bottom: 100px!important
	}
	.plr-s-0 {
		padding-left: 0px!important;
		padding-right: 0px!important
	}
	.plr-s-5 {
		padding-left: 5px!important;
		padding-right: 5px!important
	}
	.plr-s-10 {
		padding-left: 10px!important;
		padding-right: 10px!important
	}
	.plr-s-15 {
		padding-left: 15px!important;
		padding-right: 15px!important
	}
	.plr-s-20 {
		padding-left: 20px!important;
		padding-right: 20px!important
	}
	.plr-s-25 {
		padding-left: 25px!important;
		padding-right: 25px!important
	}
	.plr-s-30 {
		padding-left: 30px!important;
		padding-right: 30px!important
	}
	.plr-s-35 {
		padding-left: 35px!important;
		padding-right: 35px!important
	}
	.plr-s-40 {
		padding-left: 40px!important;
		padding-right: 40px!important
	}
	.plr-s-45 {
		padding-left: 45px!important;
		padding-right: 45px!important
	}
	.plr-s-50 {
		padding-left: 50px!important;
		padding-right: 50px!important
	}
	.plr-s-60 {
		padding-left: 60px!important;
		padding-right: 60px!important
	}
	.plr-s-70 {
		padding-left: 70px!important;
		padding-right: 70px!important
	}
	.plr-s-80 {
		padding-left: 80px!important;
		padding-right: 80px!important
	}
	.plr-s-90 {
		padding-left: 90px!important;
		padding-right: 90px!important
	}
	.plr-s-100 {
		padding-left: 100px!important;
		padding-right: 100px!important
	}
	h1, .h1 {
		font-size: 32px
	}
	h2, .h2 {
		font-size: 26px
	}
	h3, .h3 {
		font-size: 20px
	}
	h4, .h4 {
		font-size: 16px
	}
	h5, .h5 {
		font-size: 12px
	}
	h6, .h6 {
		font-size: 10px
	}
	.colp-s-0, .cp-s-0 {
		margin: 0!important
	}
	.colp-s-5, .cp-s-5 {
		margin: 0 -5px!important
	}
	.colp-s-10, .cp-s-10 {
		margin: 0 -10px!important
	}
	.colp-s-15, .cp-s-15 {
		margin: 0 -15px!important
	}
	.colp-s-20, .cp-s-20 {
		margin: 0 -20px!important
	}
	.colp-s-0 [class*="col-"], .cp-s-0 [class*="col-"] {
		padding: 0
	}
	.colp-s-5 [class*="col-"], .cp-s-5 [class*="col-"] {
		padding: 0 5px
	}
	.colp-s-10 [class*="col-"], .cp-s-10 [class*="col-"] {
		padding: 0 10px
	}
	.colp-s-15 [class*="col-"], .cp-s-15 [class*="col-"] {
		padding: 0 15px
	}
	.colp-s-20 [class*="col-"], .cp-s-20 [class*="col-"] {
		padding: 0 20px
	}
	.zi-s--1 {
		z-index: -1;
	}
	.zi-s-0 {
		z-index: 0;
	}
	.zi-s-1 {
		z-index: 1;
	}
	.zi-s-2 {
		z-index: 2;
	}
	.zi-s-3 {
		z-index: 3;
	}
	.zi-s-inf {
		z-index: 9999999999999;
	}
}
@media only screen and (max-width: 767px) {
	.container, .container-p, .c, .c-p {
		width: 100%
	}
	.col-xs-0 {
		width: 0%
	}
	.col-xs-5 {
		width: 5%
	}
	.col-xs-8 {
		width: 8.3333333%
	}
	.col-xs-10 {
		width: 10%
	}
	.col-xs-15 {
		width: 15%
	}
	.col-xs-16, .col-xs-17 {
		width: 16.6666667%
	}
	.col-xs-20 {
		width: 20%
	}
	.col-xs-25 {
		width: 25%
	}
	.col-xs-30 {
		width: 30%
	}
	.col-xs-33, .col-xs-34 {
		width: 33.3333333%
	}
	.col-xs-35 {
		width: 35%
	}
	.col-xs-40 {
		width: 40%
	}
	.col-xs-42 {
		width: 41.6666666%
	}
	.col-xs-45 {
		width: 45%
	}
	.col-xs-50 {
		width: 50%
	}
	.col-xs-55 {
		width: 55%
	}
	.col-xs-58 {
		width: 58.3333333%
	}
	.col-xs-60 {
		width: 60%
	}
	.col-xs-65 {
		width: 65%
	}
	.col-xs-66, .col-xs-67 {
		width: 66.6666666%
	}
	.col-xs-70 {
		width: 70%
	}
	.col-xs-75, .col-xs-76 {
		width: 75%
	}
	.col-xs-80 {
		width: 80%
	}
	.col-xs-83, .col-xs-84 {
		width: 83.3333333%
	}
	.col-xs-85 {
		width: 85%
	}
	.col-xs-90 {
		width: 90%
	}
	.col-xs-92 {
		width: 91.6666667%
	}
	.col-xs-95 {
		width: 95%
	}
	.col-xs-100 {
		width: 100%
	}
	.stredges-xs, .se-xs {
		margin-left: -15px!important;
		margin-right: -15px!important
	}
	.stredges-xs-0, .se-xs-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.pe-xs-auto, .pe-xs-a {
		pointer-events: auto
	}
	.pe-xs-none, .pe-xs-n {
		pointer-events: none
	}
	.offset-xs-0, .offset-xs-none, .of-xs-n, .of-xs-0 {
		margin-left: 0!important
	}
	.push-xs-0, .push-xs-none, .push-xs-n {
		left: 0!important
	}
	.pull-xs-0, .pull-xs-none, .pull-xs-n {
		right: 0!important
	}
	.t-xs-center, .t-xs-c {
		text-align: center!important
	}
	.t-xs-left, .t-xs-l {
		text-align: left!important
	}
	.t-xs-right, .t-xs-r {
		text-align: right!important
	}
	.t-xs-justify, .t-xs-j {
		text-align: justify!important;
		-ms-text-align-last: justify!important;
		text-align-last: justify!important
	}
	.va-xs-top, .va-xs-t {
		vertical-align: middle
	}
	.va-xs-bottom, .va-xs-b {
		vertical-align: middle
	}
	.va-xs-middle, .va-xs-m {
		vertical-align: middle
	}
	.fd-xs-row, .fd-xs-r {
		-webkit-flex-direction: row;
		flex-direction: row
	}
	.fd-xs-rowrs, .fd-xs-rr {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.fd-xs-col, .fd-xs-c {
		-webkit-flex-direction: column;
		flex-direction: column
	}
	.fd-xs-colrs, .fd-xs-cr {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.fw-xs-wrap, .fw-xs-w {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.fw-xs-nowrap, .fw-xs-nw {
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.fw-xs-wraprs, .fw-xs-wr {
		-webkit-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse
	}
	.ff-xs-normal, .ff-xs-n {
		-webkit-flex-flow: row wrap;
		flex-flow: row wrap
	}
	.jc-xs-start, .jc-xs-s {
		-webkit-justify-content: flex-start;
		justify-content: flex-start
	}
	.jc-xs-center, .jc-xs-c {
		-webkit-justify-content: center;
		justify-content: center
	}
	.jc-xs-end, .jc-xs-e {
		-webkit-justify-content: flex-end;
		justify-content: flex-end
	}
	.jc-xs-sbetween, .jc-xs-sb {
		-webkit-justify-content: space-between;
		justify-content: space-between
	}
	.jc-xs-saround, .jc-xs-sa {
		-webkit-justify-content: space-around;
		justify-content: space-around
	}
	.jc-xs-sevenly, .jc-xs-se {
		-webkit-justify-content: space-evenly;
		justify-content: space-evenly
	}
	.ac-xs-start, .ac-xs-s {
		-webkit-align-content: flex-start;
		align-content: flex-start
	}
	.ac-xs-center, .ac-xs-c {
		-webkit-align-content: center;
		align-content: center
	}
	.ac-xs-end, .ac-xs-e {
		-webkit-align-content: flex-end;
		align-content: flex-end
	}
	.ac-xs-stretch, .ac-xs-str {
		-webkit-align-content: stretch;
		align-content: stretch
	}
	.ac-xs-sbetween, .ac-xs-sb {
		-webkit-align-content: space-between;
		align-content: space-between
	}
	.ac-xs-saround, .ac-xs-sa {
		-webkit-align-content: space-around;
		align-content: space-around
	}
	.ai-xs-start, .ai-xs-s {
		-webkit-align-items: flex-start;
		align-items: flex-start
	}
	.ai-xs-center, .ai-xs-c {
		-webkit-align-items: center;
		align-items: center
	}
	.ai-xs-end, .ai-xs-e {
		-webkit-align-items: flex-end;
		align-items: flex-end
	}
	.ai-xs-base, .ai-xs-bs {
		-webkit-align-items: baseline;
		align-items: baseline
	}
	.ai-xs-stretch, .ai-xs-str {
		-webkit-align-items: stretch;
		align-items: stretch
	}
	.as-xs-start, .as-xs-s {
		-webkit-align-self: flex-start;
		align-self: flex-start
	}
	.as-xs-center, .as-xs-c {
		-webkit-align-self: center;
		align-self: center
	}
	.as-xs-end, .as-xs-e {
		-webkit-align-self: flex-end;
		align-self: flex-end
	}
	.as-xs-base, .as-xs-bs {
		-webkit-align-self: baseline;
		align-self: baseline
	}
	.as-xs-stretch, .as-xs-str {
		-webkit-align-self: stretch;
		align-self: stretch
	}
	.order-xs--1, .or-xs--1 {
		order: -1
	}
	.order-xs-0, .or-xs-0 {
		order: 0
	}
	.order-xs-1, .or-xs-1 {
		order: 1
	}
	.lh-xs-0 {
		line-height: 0
	}
	.lh-xs-1 {
		line-height: 1
	}
	.ofw-xs-h {
		overflow: hidden;
	}
	.ofwx-xs-h {
		overflow-x: hidden;
	}
	.ofwy-xs-h {
		overflow-x: hidden;
	}
	.ofw-xs-a {
		overflow: auto;
	}
	.ofwx-xs-a {
		overflow-x: auto;
	}
	.ofwy-s-a {
		overflow-x: auto;
	}
	.ofw-xs-s {
		overflow: scroll;
	}
	.ofwx-xs-s {
		overflow-x: scroll;
	}
	.ofwy-xs-s {
		overflow-x: scroll;
	}
	.h-xs-inherit, .h-xs-i {
		height: inherit
	}
	.w-xs-inherit, .w-xs-i {
		width: inherit
	}
	/*EXPERIMENTAL VARS*/
	.m-xs-1 {
		margin: var(--margin-1, 5px)!important
	}
	.m-xs-2 {
		margin: var(--margin-2, 15px)!important
	}
	.m-xs-3 {
		margin: var(--margin-3, 20px)!important
	}
	.m-xs-4 {
		margin: var(--margin-4, 30px)!important
	}
	.m-xs-5 {
		margin: var(--margin-5, 50px)!important
	}
	.mt-xs-1 {
		margin-top: var(--margin-1, 5px)!important
	}
	.mt-xs-2 {
		margin-top: var(--margin-2, 15px)!important
	}
	.mt-xs-3 {
		margin-top: var(--margin-3, 20px)!important
	}
	.mt-xs-4 {
		margin-top: var(--margin-4, 30px)!important
	}
	.mt-xs-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mr-xs-1 {
		margin-right: var(--margin-1, 5px)!important
	}
	.mr-xs-2 {
		margin-right: var(--margin-2, 15px)!important
	}
	.mr-xs-3 {
		margin-right: var(--margin-3, 20px)!important
	}
	.mr-xs-4 {
		margin-right: var(--margin-4, 30px)!important
	}
	.mr-xs-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mb-xs-1 {
		margin-bottom: var(--margin-1, 5px)!important
	}
	.mb-xs-2 {
		margin-bottom: var(--margin-2, 15px)!important
	}
	.mb-xs-3 {
		margin-bottom: var(--margin-3, 20px)!important
	}
	.mb-xs-4 {
		margin-bottom: var(--margin-4, 30px)!important
	}
	.mb-xs-5 {
		margin-bottom: var(--margin-5, 50px)!important
	}
	.ml-xs-1 {
		margin-left: var(--margin-1, 5px)!important
	}
	.ml-xs-2 {
		margin-left: var(--margin-2, 15px)!important
	}
	.ml-xs-3 {
		margin-left: var(--margin-3, 20px)!important
	}
	.ml-xs-4 {
		margin-left: var(--margin-4, 30px)!important
	}
	.ml-xs-5 {
		margin-left: var(--margin-5, 50px)!important
	}
	/*EXPERIMENTAL VARS*/
	.m-xs-0 {
		margin: 0!important
	}
	.mt-xs-0 {
		margin-top: 0!important
	}
	.mt-xs-5 {
		margin-top: 5px!important
	}
	.mt-xs-10 {
		margin-top: 10px!important
	}
	.mt-xs-15 {
		margin-top: 15px!important
	}
	.mt-xs-20 {
		margin-top: 20px!important
	}
	.mt-xs-25 {
		margin-top: 25px!important
	}
	.mt-xs-30 {
		margin-top: 30px!important
	}
	.mt-xs-35 {
		margin-top: 35px!important
	}
	.mt-xs-40 {
		margin-top: 40px!important
	}
	.mt-xs-45 {
		margin-top: 45px!important
	}
	.mt-xs-50 {
		margin-top: 50px!important
	}
	.mr-xs-0 {
		margin-right: 0!important
	}
	.mr-xs-5 {
		margin-right: 5px!important
	}
	.mr-xs-10 {
		margin-right: 10px!important
	}
	.mr-xs-15 {
		margin-right: 15px!important
	}
	.mr-xs-20 {
		margin-right: 20px!important
	}
	.mr-xs-25 {
		margin-right: 25px!important
	}
	.mr-xs-30 {
		margin-right: 30px!important
	}
	.mr-xs-35 {
		margin-right: 35px!important
	}
	.mr-xs-40 {
		margin-right: 40px!important
	}
	.mr-xs-45 {
		margin-right: 45px!important
	}
	.mr-xs-50 {
		margin-right: 50px!important
	}
	.mb-xs-0 {
		margin-bottom: 0!important
	}
	.mb-xs-5 {
		margin-bottom: 5px!important
	}
	.mb-xs-10 {
		margin-bottom: 10px!important
	}
	.mb-xs-15 {
		margin-bottom: 15px!important
	}
	.mb-xs-20 {
		margin-bottom: 20px!important
	}
	.mb-xs-25 {
		margin-bottom: 25px!important
	}
	.mb-xs-30 {
		margin-bottom: 30px!important
	}
	.mb-xs-35 {
		margin-bottom: 35px!important
	}
	.mb-xs-40 {
		margin-bottom: 40px!important
	}
	.mb-xs-45 {
		margin-bottom: 45px!important
	}
	.mb-xs-50 {
		margin-bottom: 50px!important
	}
	.ml-xs-0 {
		margin-left: 0!important
	}
	.ml-xs-5 {
		margin-left: 5px!important
	}
	.ml-xs-10 {
		margin-left: 10px!important
	}
	.ml-xs-15 {
		margin-left: 15px!important
	}
	.ml-xs-20 {
		margin-left: 20px!important
	}
	.ml-xs-25 {
		margin-left: 25px!important
	}
	.ml-xs-30 {
		margin-left: 30px!important
	}
	.ml-xs-35 {
		margin-left: 35px!important
	}
	.ml-xs-40 {
		margin-left: 40px!important
	}
	.ml-xs-45 {
		margin-left: 45px!important
	}
	.ml-xs-50 {
		margin-left: 50px!important
	}
	.mtb-xs-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.mtb-xs-5 {
		margin-top: 5px!important;
		margin-bottom: 5px!important
	}
	.mtb-xs-10 {
		margin-top: 10px!important;
		margin-bottom: 10px!important
	}
	.mtb-xs-15 {
		margin-top: 15px!important;
		margin-bottom: 15px!important
	}
	.mtb-xs-20 {
		margin-top: 20px!important;
		margin-bottom: 20px!important
	}
	.mtb-xs-25 {
		margin-top: 25px!important;
		margin-bottom: 25px!important
	}
	.mtb-xs-30 {
		margin-top: 30px!important;
		margin-bottom: 30px!important
	}
	.mtb-xs-35 {
		margin-top: 35px!important;
		margin-bottom: 35px!important
	}
	.mtb-xs-40 {
		margin-top: 40px!important;
		margin-bottom: 40px!important
	}
	.mtb-xs-45 {
		margin-top: 45px!important;
		margin-bottom: 45px!important
	}
	.mtb-xs-50 {
		margin-top: 50px!important;
		margin-bottom: 50px!important
	}
	.mtb-xs-60 {
		margin-top: 60px!important;
		margin-bottom: 60px!important
	}
	.mtb-xs-70 {
		margin-top: 70px!important;
		margin-bottom: 70px!important
	}
	.mtb-xs-80 {
		margin-top: 80px!important;
		margin-bottom: 80px!important
	}
	.mtb-xs-90 {
		margin-top: 90px!important;
		margin-bottom: 90px!important
	}
	.mtb-xs-100 {
		margin-top: 100px!important;
		margin-bottom: 100px!important
	}
	.mlr-xs-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.mlr-xs-5 {
		margin-left: 5px!important;
		margin-right: 5px!important
	}
	.mlr-xs-10 {
		margin-left: 10px!important;
		margin-right: 10px!important
	}
	.mlr-xs-15 {
		margin-left: 15px!important;
		margin-right: 15px!important
	}
	.mlr-xs-20 {
		margin-left: 20px!important;
		margin-right: 20px!important
	}
	.mlr-xs-25 {
		margin-left: 25px!important;
		margin-right: 25px!important
	}
	.mlr-xs-30 {
		margin-left: 30px!important;
		margin-right: 30px!important
	}
	.mlr-xs-35 {
		margin-left: 35px!important;
		margin-right: 35px!important
	}
	.mlr-xs-40 {
		margin-left: 40px!important;
		margin-right: 40px!important
	}
	.mlr-xs-45 {
		margin-left: 45px!important;
		margin-right: 45px!important
	}
	.mlr-xs-50 {
		margin-left: 50px!important;
		margin-right: 50px!important
	}
	.mlr-xs-60 {
		margin-left: 60px!important;
		margin-right: 60px!important
	}
	.mlr-xs-70 {
		margin-left: 70px!important;
		margin-right: 70px!important
	}
	.mlr-xs-80 {
		margin-left: 80px!important;
		margin-right: 80px!important
	}
	.mlr-xs-90 {
		margin-left: 90px!important;
		margin-right: 90px!important
	}
	.mlr-xs-100 {
		margin-left: 100px!important;
		margin-right: 100px!important
	}
	.p-xs-0 {
		padding: 0!important
	}
	.pt-xs-0 {
		padding-top: 0!important
	}
	.pt-xs-5 {
		padding-top: 5px!important
	}
	.pt-xs-10 {
		padding-top: 10px!important
	}
	.pt-xs-15 {
		padding-top: 15px!important
	}
	.pt-xs-20 {
		padding-top: 20px!important
	}
	.pt-xs-25 {
		padding-top: 25px!important
	}
	.pt-xs-30 {
		padding-top: 30px!important
	}
	.pt-xs-35 {
		padding-top: 35px!important
	}
	.pt-xs-40 {
		padding-top: 40px!important
	}
	.pt-xs-45 {
		padding-top: 45px!important
	}
	.pt-xs-50 {
		padding-top: 50px!important
	}
	.pr-xs-0 {
		padding-right: 0!important
	}
	.pr-xs-5 {
		padding-right: 5px!important
	}
	.pr-xs-10 {
		padding-right: 10px!important
	}
	.pr-xs-15 {
		padding-right: 15px!important
	}
	.pr-xs-20 {
		padding-right: 20px!important
	}
	.pr-xs-25 {
		padding-right: 25px!important
	}
	.pr-xs-30 {
		padding-right: 30px!important
	}
	.pr-xs-35 {
		padding-right: 35px!important
	}
	.pr-xs-40 {
		padding-right: 40px!important
	}
	.pr-xs-45 {
		padding-right: 45px!important
	}
	.pr-xs-50 {
		padding-right: 50px!important
	}
	.pb-xs-0 {
		padding-bottom: 0!important
	}
	.pb-xs-5 {
		padding-bottom: 5px!important
	}
	.pb-xs-10 {
		padding-bottom: 10px!important
	}
	.pb-xs-15 {
		padding-bottom: 15px!important
	}
	.pb-xs-20 {
		padding-bottom: 20px!important
	}
	.pb-xs-25 {
		padding-bottom: 25px!important
	}
	.pb-xs-30 {
		padding-bottom: 30px!important
	}
	.pb-xs-35 {
		padding-bottom: 35px!important
	}
	.pb-xs-40 {
		padding-bottom: 40px!important
	}
	.pb-xs-45 {
		padding-bottom: 45px!important
	}
	.pb-xs-50 {
		padding-bottom: 50px!important
	}
	.pl-xs-0 {
		padding-left: 0!important
	}
	.pl-xs-5 {
		padding-left: 5px!important
	}
	.pl-xs-10 {
		padding-left: 10px!important
	}
	.pl-xs-15 {
		padding-left: 15px!important
	}
	.pl-xs-20 {
		padding-left: 20px!important
	}
	.pl-xs-25 {
		padding-left: 25px!important
	}
	.pl-xs-30 {
		padding-left: 30px!important
	}
	.pl-xs-35 {
		padding-left: 35px!important
	}
	.pl-xs-40 {
		padding-left: 40px!important
	}
	.pl-xs-45 {
		padding-left: 45px!important
	}
	.pl-xs-50 {
		padding-left: 50px!important
	}
	.p-xs-0 {
		padding: 0!important
	}
	.p-xs-5 {
		padding: 5px!important
	}
	.p-xs-10 {
		padding: 10px!important
	}
	.p-xs-15 {
		padding: 15px!important
	}
	.p-xs-20 {
		padding: 20px!important
	}
	.p-xs-25 {
		padding: 25px!important
	}
	.p-xs-30 {
		padding: 30px!important
	}
	.p-xs-35 {
		padding: 35px!important
	}
	.p-xs-40 {
		padding: 40px!important
	}
	.p-xs-45 {
		padding: 45px!important
	}
	.p-xs-50 {
		padding: 50px!important
	}
	.ptb-xs-0 {
		padding-top: 0px!important;
		padding-bottom: 0px!important
	}
	.ptb-xs-5 {
		padding-top: 5px!important;
		padding-bottom: 5px!important
	}
	.ptb-xs-10 {
		padding-top: 10px!important;
		padding-bottom: 10px!important
	}
	.ptb-xs-15 {
		padding-top: 15px!important;
		padding-bottom: 15px!important
	}
	.ptb-xs-20 {
		padding-top: 20px!important;
		padding-bottom: 20px!important
	}
	.ptb-xs-25 {
		padding-top: 25px!important;
		padding-bottom: 25px!important
	}
	.ptb-xs-30 {
		padding-top: 30px!important;
		padding-bottom: 30px!important
	}
	.ptb-xs-35 {
		padding-top: 35px!important;
		padding-bottom: 35px!important
	}
	.ptb-xs-40 {
		padding-top: 40px!important;
		padding-bottom: 40px!important
	}
	.ptb-xs-45 {
		padding-top: 45px!important;
		padding-bottom: 45px!important
	}
	.ptb-xs-50 {
		padding-top: 50px!important;
		padding-bottom: 50px!important
	}
	.ptb-xs-60 {
		padding-top: 60px!important;
		padding-bottom: 60px!important
	}
	.ptb-xs-70 {
		padding-top: 70px!important;
		padding-bottom: 70px!important
	}
	.ptb-xs-80 {
		padding-top: 80px!important;
		padding-bottom: 80px!important
	}
	.ptb-xs-90 {
		padding-top: 90px!important;
		padding-bottom: 90px!important
	}
	.ptb-xs-100 {
		padding-top: 100px!important;
		padding-bottom: 100px!important
	}
	.plr-xs-0 {
		padding-left: 0px!important;
		padding-right: 0px!important
	}
	.plr-xs-5 {
		padding-left: 5px!important;
		padding-right: 5px!important
	}
	.plr-xs-10 {
		padding-left: 10px!important;
		padding-right: 10px!important
	}
	.plr-xs-15 {
		padding-left: 15px!important;
		padding-right: 15px!important
	}
	.plr-xs-20 {
		padding-left: 20px!important;
		padding-right: 20px!important
	}
	.plr-xs-25 {
		padding-left: 25px!important;
		padding-right: 25px!important
	}
	.plr-xs-30 {
		padding-left: 30px!important;
		padding-right: 30px!important
	}
	.plr-xs-35 {
		padding-left: 35px!important;
		padding-right: 35px!important
	}
	.plr-xs-40 {
		padding-left: 40px!important;
		padding-right: 40px!important
	}
	.plr-xs-45 {
		padding-left: 45px!important;
		padding-right: 45px!important
	}
	.plr-xs-50 {
		padding-left: 50px!important;
		padding-right: 50px!important
	}
	.plr-xs-60 {
		padding-left: 60px!important;
		padding-right: 60px!important
	}
	.plr-xs-70 {
		padding-left: 70px!important;
		padding-right: 70px!important
	}
	.plr-xs-80 {
		padding-left: 80px!important;
		padding-right: 80px!important
	}
	.plr-xs-90 {
		padding-left: 90px!important;
		padding-right: 90px!important
	}
	.plr-xs-100 {
		padding-left: 100px!important;
		padding-right: 100px!important
	}
	h1, .h1 {
		font-size: 28px
	}
	h2, .h2 {
		font-size: 22px
	}
	h3, .h3 {
		font-size: 16px
	}
	h4, .h4 {
		font-size: 14px
	}
	h5, .h5 {
		font-size: 12px
	}
	h6, .h6 {
		font-size: 8px
	}
	.data, .data p, .data a, .data span, .data ul, .data ol {
		font-size: 14px
	}
	.colp-xs-0, .cp-xs-0 {
		margin: 0!important
	}
	.colp-xs-5, .cp-xs-5 {
		margin: 0 -5px!important
	}
	.colp-xs-10, .cp-xs-10 {
		margin: 0 -10px!important
	}
	.colp-xs-15, .cp-xs-15 {
		margin: 0 -15px!important
	}
	.colp-xs-20, .cp-xs-20 {
		margin: 0 -20px!important
	}
	.colp-xs-0 [class*="col-"], .cp-xs-0 [class*="col-"] {
		padding: 0
	}
	.colp-xs-5 [class*="col-"], .cp-xs-5 [class*="col-"] {
		padding: 0 5px
	}
	.colp-xs-10 [class*="col-"], .cp-xs-10 [class*="col-"] {
		padding: 0 10px
	}
	.colp-xs-15 [class*="col-"], .cp-xs-5 [class*="col-"] {
		padding: 0 15px
	}
	.colp-xs-20 [class*="col-"], .cp-xs-20 [class*="col-"] {
		padding: 0 20px
	}
	.zi-xs--1 {
		z-index: -1;
	}
	.zi-xs-0 {
		z-index: 0;
	}
	.zi-xs-1 {
		z-index: 1;
	}
	.zi-xs-2 {
		z-index: 2;
	}
	.zi-xs-3 {
		z-index: 3;
	}
	.zi-xs-inf {
		z-index: 9999999999999;
	}
	/*If don't need to use - DELETE*/
	.data table, .data table td, table, table td {
		font-size: 12px
	}
	/*If don't need to use - DELETE*/
}
@media only screen and (max-width: 479px) {
	.col-xxs-0 {
		width: 0%
	}
	.col-xxs-5 {
		width: 5%
	}
	.col-xxs-8 {
		width: 8.3333333%
	}
	.col-xxs-10 {
		width: 10%
	}
	.col-xxs-15 {
		width: 15%
	}
	.col-xxs-16, .col-xxs-17 {
		width: 16.6666667%
	}
	.col-xxs-20 {
		width: 20%
	}
	.col-xxs-25 {
		width: 25%
	}
	.col-xxs-30 {
		width: 30%
	}
	.col-xxs-33, .col-xxs-34 {
		width: 33.3333333%
	}
	.col-xxs-35 {
		width: 35%
	}
	.col-xxs-40 {
		width: 40%
	}
	.col-xxs-42 {
		width: 41.6666666%
	}
	.col-xxs-45 {
		width: 45%
	}
	.col-xxs-50 {
		width: 50%
	}
	.col-xxs-55 {
		width: 55%
	}
	.col-xxs-58 {
		width: 58.3333333%
	}
	.col-xxs-60 {
		width: 60%
	}
	.col-xxs-65 {
		width: 65%
	}
	.col-xxs-66, .col-xxs-67 {
		width: 66.6666666%
	}
	.col-xxs-70 {
		width: 70%
	}
	.col-xxs-75, .col-xxs-76 {
		width: 75%
	}
	.col-xxs-80 {
		width: 80%
	}
	.col-xxs-83, .col-xxs-84 {
		width: 83.3333333%
	}
	.col-xxs-85 {
		width: 85%
	}
	.col-xxs-90 {
		width: 90%
	}
	.col-xxs-92 {
		width: 91.6666667%
	}
	.col-xxs-95 {
		width: 95%
	}
	.col-xxs-100 {
		width: 100%
	}
	.stredges-xxs, .se-xxs {
		margin-left: -15px!important;
		margin-right: -15px!important
	}
	.stredges-xxs-0, .se-xxs-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.pe-xxs-auto, .pe-xxs-a {
		pointer-events: auto
	}
	.pe-xxs-none, .pe-xxs-n {
		pointer-events: none
	}
	.offset-xxs-0, .offset-xxs-none, .of-xxs-n, .of-xxs-0 {
		margin-left: 0!important
	}
	.push-xxs-0, .push-xxs-none, .push-xxs-n {
		left: 0!important
	}
	.pull-xxs-0, .pull-xxs-none, .pull-xxs-n {
		right: 0!important
	}
	.t-xxs-center, .t-xxs-c {
		text-align: center!important
	}
	.t-xxs-left, .t-xxs-l {
		text-align: left!important
	}
	.t-xxs-right, .t-xxs-r {
		text-align: right!important
	}
	.t-xxs-justify, .t-xxs-j {
		text-align: justify!important;
		-ms-text-align-last: justify!important;
		text-align-last: justify!important
	}
	.va-xxs-top, .va-xxs-t {
		vertical-align: middle
	}
	.va-xxs-bottom, .va-xxs-b {
		vertical-align: middle
	}
	.va-xxs-middle, .va-xxs-m {
		vertical-align: middle
	}
	.fd-xxs-row, .fd-xxs-r {
		-webkit-flex-direction: row;
		flex-direction: row
	}
	.fd-xxs-rowrs, .fd-xxs-rr {
		-webkit-flex-direction: row-reverse;
		flex-direction: row-reverse
	}
	.fd-xxs-col, .fd-xxs-c {
		-webkit-flex-direction: column;
		flex-direction: column
	}
	.fd-xxs-colrs, .fd-xxs-cr {
		-webkit-flex-direction: column-reverse;
		flex-direction: column-reverse
	}
	.fw-xxs-wrap, .fw-xxs-w {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap
	}
	.fw-xxs-nowrap, .fw-xxs-nw {
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap
	}
	.fw-xxs-wraprs, .fw-xxs-wr {
		-webkit-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse
	}
	.ff-xxs-normal, .ff-xxs-n {
		-webkit-flex-flow: row wrap;
		flex-flow: row wrap
	}
	.jc-xxs-start, .jc-xxs-s {
		-webkit-justify-content: flex-start;
		justify-content: flex-start
	}
	.jc-xxs-center, .jc-xxs-c {
		-webkit-justify-content: center;
		justify-content: center
	}
	.jc-xxs-end, .jc-xxs-e {
		-webkit-justify-content: flex-end;
		justify-content: flex-end
	}
	.jc-xxs-sbetween, .jc-xxs-sb {
		-webkit-justify-content: space-between;
		justify-content: space-between
	}
	.jc-xxs-saround, .jc-xxs-sa {
		-webkit-justify-content: space-around;
		justify-content: space-around
	}
	.jc-xxs-sevenly, .jc-xxs-se {
		-webkit-justify-content: space-evenly;
		justify-content: space-evenly
	}
	.ac-xxs-start, .ac-xxs-s {
		-webkit-align-content: flex-start;
		align-content: flex-start
	}
	.ac-xxs-center, .ac-xxs-c {
		-webkit-align-content: center;
		align-content: center
	}
	.ac-xxs-end, .ac-xxs-e {
		-webkit-align-content: flex-end;
		align-content: flex-end
	}
	.ac-xxs-stretch, .ac-xxs-str {
		-webkit-align-content: stretch;
		align-content: stretch
	}
	.ac-xxs-sbetween, .ac-xxs-sb {
		-webkit-align-content: space-between;
		align-content: space-between
	}
	.ac-xxs-saround, .ac-xxs-sa {
		-webkit-align-content: space-around;
		align-content: space-around
	}
	.ai-xxs-start, .ai-xxs-s {
		-webkit-align-items: flex-start;
		align-items: flex-start
	}
	.ai-xxs-center, .ai-xxs-c {
		-webkit-align-items: center;
		align-items: center
	}
	.ai-xxs-end, .ai-xxs-e {
		-webkit-align-items: flex-end;
		align-items: flex-end
	}
	.ai-xxs-base, .ai-xxs-bs {
		-webkit-align-items: baseline;
		align-items: baseline
	}
	.ai-xxs-stretch, .ai-xxs-str {
		-webkit-align-items: stretch;
		align-items: stretch
	}
	.as-xxs-start, .as-xxs-s {
		-webkit-align-self: flex-start;
		align-self: flex-start
	}
	.as-xxs-center, .as-xxs-c {
		-webkit-align-self: center;
		align-self: center
	}
	.as-xxs-end, .as-xxs-e {
		-webkit-align-self: flex-end;
		align-self: flex-end
	}
	.as-xxs-base, .as-xxs-bs {
		-webkit-align-self: baseline;
		align-self: baseline
	}
	.as-xxs-stretch, .as-xxs-str {
		-webkit-align-self: stretch;
		align-self: stretch
	}
	.order-xxs--1, .or-xxs--1 {
		order: -1
	}
	.order-xxs-0, .or-xxs-0 {
		order: 0
	}
	.order-xxs-1, .or-xxs-1 {
		order: 1
	}
	.lh-xxs-0 {
		line-height: 0
	}
	.lh-xxs-1 {
		line-height: 1
	}
	.ofw-xxs-h {
		overflow: hidden;
	}
	.ofwx-xxs-h {
		overflow-x: hidden;
	}
	.ofwy-xxs-h {
		overflow-x: hidden;
	}
	.ofw-xxs-a {
		overflow: auto;
	}
	.ofwx-xxs-a {
		overflow-x: auto;
	}
	.ofwy-xxs-a {
		overflow-x: auto;
	}
	.ofw-xxs-s {
		overflow: scroll;
	}
	.ofwx-xxs-s {
		overflow-x: scroll;
	}
	.ofwy-xxs-s {
		overflow-x: scroll;
	}
	.h-xxs-inherit, .h-xxs-i {
		height: inherit
	}
	.w-xxs-inherit, .w-xxs-i {
		width: inherit
	}
	/*EXPERIMENTAL VARS*/
	.m-xxs-1 {
		margin: var(--margin-1, 5px)!important
	}
	.m-xxs-2 {
		margin: var(--margin-2, 15px)!important
	}
	.m-xxs-3 {
		margin: var(--margin-3, 20px)!important
	}
	.m-xxs-4 {
		margin: var(--margin-4, 30px)!important
	}
	.m-xxs-5 {
		margin: var(--margin-5, 50px)!important
	}
	.mt-xxs-1 {
		margin-top: var(--margin-1, 5px)!important
	}
	.mt-xxs-2 {
		margin-top: var(--margin-2, 15px)!important
	}
	.mt-xxs-3 {
		margin-top: var(--margin-3, 20px)!important
	}
	.mt-xxs-4 {
		margin-top: var(--margin-4, 30px)!important
	}
	.mt-xxs-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mr-xxs-1 {
		margin-right: var(--margin-1, 5px)!important
	}
	.mr-xxs-2 {
		margin-right: var(--margin-2, 15px)!important
	}
	.mr-xxs-3 {
		margin-right: var(--margin-3, 20px)!important
	}
	.mr-xxs-4 {
		margin-right: var(--margin-4, 30px)!important
	}
	.mr-xxs-5 {
		margin-top: var(--margin-5, 50px)!important
	}
	.mb-m-1 {
		margin-bottom: var(--margin-1, 5px)!important
	}
	.mb-xxs-2 {
		margin-bottom: var(--margin-2, 15px)!important
	}
	.mb-xxs-3 {
		margin-bottom: var(--margin-3, 20px)!important
	}
	.mb-xxs-4 {
		margin-bottom: var(--margin-4, 30px)!important
	}
	.mb-xxs-5 {
		margin-bottom: var(--margin-5, 50px)!important
	}
	.ml-xxs-1 {
		margin-left: var(--margin-1, 5px)!important
	}
	.ml-xxs-2 {
		margin-left: var(--margin-2, 15px)!important
	}
	.ml-xxs-3 {
		margin-left: var(--margin-3, 20px)!important
	}
	.ml-xxs-4 {
		margin-left: var(--margin-4, 30px)!important
	}
	.ml-xxs-5 {
		margin-left: var(--margin-5, 50px)!important
	}
	/*EXPERIMENTAL VARS*/
	.m-xxs-0 {
		margin: 0!important
	}
	.mt-xxs-0 {
		margin-top: 0!important
	}
	.mt-xxs-5 {
		margin-top: 5px!important
	}
	.mt-xxs-10 {
		margin-top: 10px!important
	}
	.mt-xxs-15 {
		margin-top: 15px!important
	}
	.mt-xxs-20 {
		margin-top: 20px!important
	}
	.mt-xxs-25 {
		margin-top: 25px!important
	}
	.mt-xxs-30 {
		margin-top: 30px!important
	}
	.mt-xxs-35 {
		margin-top: 35px!important
	}
	.mt-xxs-40 {
		margin-top: 40px!important
	}
	.mt-xxs-45 {
		margin-top: 45px!important
	}
	.mt-xxs-50 {
		margin-top: 50px!important
	}
	.mr-xxs-0 {
		margin-right: 0!important
	}
	.mr-xxs-5 {
		margin-right: 5px!important
	}
	.mr-xxs-10 {
		margin-right: 10px!important
	}
	.mr-xxs-15 {
		margin-right: 15px!important
	}
	.mr-xxs-20 {
		margin-right: 20px!important
	}
	.mr-xxs-25 {
		margin-right: 25px!important
	}
	.mr-xxs-30 {
		margin-right: 30px!important
	}
	.mr-xxs-35 {
		margin-right: 35px!important
	}
	.mr-xxs-40 {
		margin-right: 40px!important
	}
	.mr-xxs-45 {
		margin-right: 45px!important
	}
	.mr-xxs-50 {
		margin-right: 50px!important
	}
	.mb-xxs-0 {
		margin-bottom: 0!important
	}
	.mb-xxs-5 {
		margin-bottom: 5px!important
	}
	.mb-xxs-10 {
		margin-bottom: 10px!important
	}
	.mb-xxs-15 {
		margin-bottom: 15px!important
	}
	.mb-xxs-20 {
		margin-bottom: 20px!important
	}
	.mb-xxs-25 {
		margin-bottom: 25px!important
	}
	.mb-xxs-30 {
		margin-bottom: 30px!important
	}
	.mb-xxs-35 {
		margin-bottom: 35px!important
	}
	.mb-xxs-40 {
		margin-bottom: 40px!important
	}
	.mb-xxs-45 {
		margin-bottom: 45px!important
	}
	.mb-xxs-50 {
		margin-bottom: 50px!important
	}
	.ml-xxs-0 {
		margin-left: 0!important
	}
	.ml-xxs-5 {
		margin-left: 5px!important
	}
	.ml-xxs-10 {
		margin-left: 10px!important
	}
	.ml-xxs-15 {
		margin-left: 15px!important
	}
	.ml-xxs-20 {
		margin-left: 20px!important
	}
	.ml-xxs-25 {
		margin-left: 25px!important
	}
	.ml-xxs-30 {
		margin-left: 30px!important
	}
	.ml-xxs-35 {
		margin-left: 35px!important
	}
	.ml-xxs-40 {
		margin-left: 40px!important
	}
	.ml-xxs-45 {
		margin-left: 45px!important
	}
	.ml-xxs-50 {
		margin-left: 50px!important
	}
	.mtb-xxs-0 {
		margin-top: 0!important;
		margin-bottom: 0!important
	}
	.mtb-xxs-5 {
		margin-top: 5px!important;
		margin-bottom: 5px!important
	}
	.mtb-xxs-10 {
		margin-top: 10px!important;
		margin-bottom: 10px!important
	}
	.mtb-xxs-15 {
		margin-top: 15px!important;
		margin-bottom: 15px!important
	}
	.mtb-xxs-20 {
		margin-top: 20px!important;
		margin-bottom: 20px!important
	}
	.mtb-xxs-25 {
		margin-top: 25px!important;
		margin-bottom: 25px!important
	}
	.mtb-xxs-30 {
		margin-top: 30px!important;
		margin-bottom: 30px!important
	}
	.mtb-xxs-35 {
		margin-top: 35px!important;
		margin-bottom: 35px!important
	}
	.mtb-xxs-40 {
		margin-top: 40px!important;
		margin-bottom: 40px!important
	}
	.mtb-xxs-45 {
		margin-top: 45px!important;
		margin-bottom: 45px!important
	}
	.mtb-xxs-50 {
		margin-top: 50px!important;
		margin-bottom: 50px!important
	}
	.mtb-xxs-60 {
		margin-top: 60px!important;
		margin-bottom: 60px!important
	}
	.mtb-xxs-70 {
		margin-top: 70px!important;
		margin-bottom: 70px!important
	}
	.mtb-xxs-80 {
		margin-top: 80px!important;
		margin-bottom: 80px!important
	}
	.mtb-xl-90 {
		margin-top: 90px!important;
		margin-bottom: 90px!important
	}
	.mtb-xxs-100 {
		margin-top: 100px!important;
		margin-bottom: 100px!important
	}
	.mlr-xxs-0 {
		margin-left: 0px!important;
		margin-right: 0px!important
	}
	.mlr-xxs-5 {
		margin-left: 5px!important;
		margin-right: 5px!important
	}
	.mlr-xxs-10 {
		margin-left: 10px!important;
		margin-right: 10px!important
	}
	.mlr-xxs-15 {
		margin-left: 15px!important;
		margin-right: 15px!important
	}
	.mlr-xxs-20 {
		margin-left: 20px!important;
		margin-right: 20px!important
	}
	.mlr-xxs-25 {
		margin-left: 25px!important;
		margin-right: 25px!important
	}
	.mlr-xxs-30 {
		margin-left: 30px!important;
		margin-right: 30px!important
	}
	.mlr-xxs-35 {
		margin-left: 35px!important;
		margin-right: 35px!important
	}
	.mlr-xxs-40 {
		margin-left: 40px!important;
		margin-right: 40px!important
	}
	.mlr-xxs-45 {
		margin-left: 45px!important;
		margin-right: 45px!important
	}
	.mlr-xxs-50 {
		margin-left: 50px!important;
		margin-right: 50px!important
	}
	.mlr-xxs-60 {
		margin-left: 60px!important;
		margin-right: 60px!important
	}
	.mlr-xxs-70 {
		margin-left: 70px!important;
		margin-right: 70px!important
	}
	.mlr-xxs-80 {
		margin-left: 80px!important;
		margin-right: 80px!important
	}
	.mlr-xxs-90 {
		margin-left: 90px!important;
		margin-right: 90px!important
	}
	.mlr-xxs-100 {
		margin-left: 100px!important;
		margin-right: 100px!important
	}
	.p-xxs-0 {
		padding: 0!important
	}
	.pt-xxs-0 {
		padding-top: 0!important
	}
	.pt-xxs-5 {
		padding-top: 5px!important
	}
	.pt-xxs-10 {
		padding-top: 10px!important
	}
	.pt-xxs-15 {
		padding-top: 15px!important
	}
	.pt-xxs-20 {
		padding-top: 20px!important
	}
	.pt-xxs-25 {
		padding-top: 25px!important
	}
	.pt-xxs-30 {
		padding-top: 30px!important
	}
	.pt-xxs-35 {
		padding-top: 35px!important
	}
	.pt-xxs-40 {
		padding-top: 40px!important
	}
	.pt-xxs-45 {
		padding-top: 45px!important
	}
	.pt-xxs-50 {
		padding-top: 50px!important
	}
	.pr-xxs-0 {
		padding-right: 0!important
	}
	.pr-xxs-5 {
		padding-right: 5px!important
	}
	.pr-xxs-10 {
		padding-right: 10px!important
	}
	.pr-xxs-15 {
		padding-right: 15px!important
	}
	.pr-xxs-20 {
		padding-right: 20px!important
	}
	.pr-xxs-25 {
		padding-right: 25px!important
	}
	.pr-xxs-30 {
		padding-right: 30px!important
	}
	.pr-xxs-35 {
		padding-right: 35px!important
	}
	.pr-xxs-40 {
		padding-right: 40px!important
	}
	.pr-xxs-45 {
		padding-right: 45px!important
	}
	.pr-xxs-50 {
		padding-right: 50px!important
	}
	.pb-xxs-0 {
		padding-bottom: 0!important
	}
	.pb-xxs-5 {
		padding-bottom: 5px!important
	}
	.pb-xxs-10 {
		padding-bottom: 10px!important
	}
	.pb-xxs-15 {
		padding-bottom: 15px!important
	}
	.pb-xxs-20 {
		padding-bottom: 20px!important
	}
	.pb-xxs-25 {
		padding-bottom: 25px!important
	}
	.pb-xxs-30 {
		padding-bottom: 30px!important
	}
	.pb-xxs-35 {
		padding-bottom: 35px!important
	}
	.pb-xxs-40 {
		padding-bottom: 40px!important
	}
	.pb-xxs-45 {
		padding-bottom: 45px!important
	}
	.pb-xxs-50 {
		padding-bottom: 50px!important
	}
	.pl-xxs-0 {
		padding-left: 0!important
	}
	.pl-xxs-5 {
		padding-left: 5px!important
	}
	.pl-xxs-10 {
		padding-left: 10px!important
	}
	.pl-xxs-15 {
		padding-left: 15px!important
	}
	.pl-xxs-20 {
		padding-left: 20px!important
	}
	.pl-xxs-25 {
		padding-left: 25px!important
	}
	.pl-xxs-30 {
		padding-left: 30px!important
	}
	.pl-xxs-35 {
		padding-left: 35px!important
	}
	.pl-xxs-40 {
		padding-left: 40px!important
	}
	.pl-xxs-45 {
		padding-left: 45px!important
	}
	.pl-xxs-50 {
		padding-left: 50px!important
	}
	.p-xxs-0 {
		padding: 0!important
	}
	.p-xxs-5 {
		padding: 5px!important
	}
	.p-xxs-10 {
		padding: 10px!important
	}
	.p-xxs-15 {
		padding: 15px!important
	}
	.p-xxs-20 {
		padding: 20px!important
	}
	.p-xxs-25 {
		padding: 25px!important
	}
	.p-xxs-30 {
		padding: 30px!important
	}
	.p-xxs-35 {
		padding: 35px!important
	}
	.p-xxs-40 {
		padding: 40px!important
	}
	.p-xxs-45 {
		padding: 45px!important
	}
	.p-xxs-50 {
		padding: 50px!important
	}
	.ptb-xxs-0 {
		padding-top: 0px!important;
		padding-bottom: 0px!important
	}
	.ptb-xxs-5 {
		padding-top: 5px!important;
		padding-bottom: 5px!important
	}
	.ptb-xxs-10 {
		padding-top: 10px!important;
		padding-bottom: 10px!important
	}
	.ptb-xxs-15 {
		padding-top: 15px!important;
		padding-bottom: 15px!important
	}
	.ptb-xxs-20 {
		padding-top: 20px!important;
		padding-bottom: 20px!important
	}
	.ptb-xxs-25 {
		padding-top: 25px!important;
		padding-bottom: 25px!important
	}
	.ptb-xxs-30 {
		padding-top: 30px!important;
		padding-bottom: 30px!important
	}
	.ptb-xxs-35 {
		padding-top: 35px!important;
		padding-bottom: 35px!important
	}
	.ptb-xxs-40 {
		padding-top: 40px!important;
		padding-bottom: 40px!important
	}
	.ptb-xxs-45 {
		padding-top: 45px!important;
		padding-bottom: 45px!important
	}
	.ptb-xxs-50 {
		padding-top: 50px!important;
		padding-bottom: 50px!important
	}
	.ptb-xxs-60 {
		padding-top: 60px!important;
		padding-bottom: 60px!important
	}
	.ptb-xxs-70 {
		padding-top: 70px!important;
		padding-bottom: 70px!important
	}
	.ptb-xxs-80 {
		padding-top: 80px!important;
		padding-bottom: 80px!important
	}
	.ptb-xxs-90 {
		padding-top: 90px!important;
		padding-bottom: 90px!important
	}
	.ptb-xxs-100 {
		padding-top: 100px!important;
		padding-bottom: 100px!important
	}
	.plr-xxs-0 {
		padding-left: 0px!important;
		padding-right: 0px!important
	}
	.plr-xxs-5 {
		padding-left: 5px!important;
		padding-right: 5px!important
	}
	.plr-xxs-10 {
		padding-left: 10px!important;
		padding-right: 10px!important
	}
	.plr-xxs-15 {
		padding-left: 15px!important;
		padding-right: 15px!important
	}
	.plr-xxs-20 {
		padding-left: 20px!important;
		padding-right: 20px!important
	}
	.plr-xxs-25 {
		padding-left: 25px!important;
		padding-right: 25px!important
	}
	.plr-xxs-30 {
		padding-left: 30px!important;
		padding-right: 30px!important
	}
	.plr-xxs-35 {
		padding-left: 35px!important;
		padding-right: 35px!important
	}
	.plr-xxs-40 {
		padding-left: 40px!important;
		padding-right: 40px!important
	}
	.plr-xxs-45 {
		padding-left: 45px!important;
		padding-right: 45px!important
	}
	.plr-xxs-50 {
		padding-left: 50px!important;
		padding-right: 50px!important
	}
	.plr-xxs-60 {
		padding-left: 60px!important;
		padding-right: 60px!important
	}
	.plr-xxs-70 {
		padding-left: 70px!important;
		padding-right: 70px!important
	}
	.plr-xxs-80 {
		padding-left: 80px!important;
		padding-right: 80px!important
	}
	.plr-xxs-90 {
		padding-left: 90px!important;
		padding-right: 90px!important
	}
	.plr-xxs-100 {
		padding-left: 100px!important;
		padding-right: 100px!important
	}
	h1, .h1 {
		font-size: 24px
	}
	h2, .h2 {
		font-size: 18px
	}
	.colp-xxs-0, .cp-xxs-0 {
		margin: 0!important
	}
	.colp-xxs-5, .cp-xxs-5 {
		margin: 0 -5px!important
	}
	.colp-xxs-10, .cp-xxs-10 {
		margin: 0 -10px!important
	}
	.colp-xxs-15, .cp-xxs-15 {
		margin: 0 -15px!important
	}
	.colp-xxs-20, .cp-xxs-20 {
		margin: 0 -20px!important
	}
	.colp-xxs-0 [class*="col-"], .cp-xxs-0 [class*="col-"] {
		padding: 0
	}
	.colp-xxs-5 [class*="col-"], .cp-xxs-5 [class*="col-"] {
		padding: 0 5px
	}
	.colp-xxs-10 [class*="col-"], .cp-xxs-10 [class*="col-"] {
		padding: 0 10px
	}
	.colp-xxs-15 [class*="col-"], .cp-xxs-15 [class*="col-"] {
		padding: 0 15px
	}
	.colp-xxs-20 [class*="col-"], .cp-xxs-20 [class*="col-"] {
		padding: 0 20px
	}
	.zi-xxs--1 {
		z-index: -1;
	}
	.zi-xxs-0 {
		z-index: 0;
	}
	.zi-xxs-1 {
		z-index: 1;
	}
	.zi-xxs-2 {
		z-index: 2;
	}
	.zi-xxs-3 {
		z-index: 3;
	}
	.zi-xxs-inf {
		z-index: 9999999999999;
	}
}
@media only screen and (min-width: 1580px) {
	.hidden-xl, .hidden-xl-d, .hidden-xxs-u, .hidden-xs-u, .hidden-s-u, .hidden-m-u, .hidden-l-u, .hidden-xl-u {
		display: none!important
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1579px) {
	.hidden-xl-d, .hidden-l, .hidden-l-d, .hidden-xxs-u, .hidden-xs-u, .hidden-s-u, .hidden-m-u, .hidden-l-u {
		display: none!important
	}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.hidden-xl-d, .hidden-l-d, .hidden-m, .hidden-m-d, .hidden-xxs-u, .hidden-xs-u, .hidden-s-u, .hidden-m-u {
		display: none!important
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.hidden-xl-d, .hidden-l-d, .hidden-m-d, .hidden-s, .hidden-s-d, .hidden-xxs-u, .hidden-xs-u, .hidden-s-u {
		display: none!important
	}
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
	.hidden-xl-d, .hidden-l-d, .hidden-m-d, .hidden-s-d, .hidden-xs, .hidden-xs-d, .hidden-xxs-u, .hidden-xs-u {
		display: none!important
	}
}
@media only screen and (min-width: 0px) and (max-width: 479px) {
	.hidden-xl-d, .hidden-l-d, .hidden-m-d, .hidden-s-d, .hidden-xs-d, .hidden-xxs, .hidden-xxs-d, .hidden-xxs-u {
		display: none!important
	}
}